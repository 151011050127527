import React, { useContext, useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import Table from '../../components/Table/Table';
import { getRegions } from '../../services/catalog/catalog';
import { getSchoolStatistics } from '../../services/schoolStatistics/schoolStatistics';
import { UserContext } from '../../utils/contexts/UserContext';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import SimpleLoader from '../../components/Loader/SimpleLoader';

export default function SchoolRegistrationStatistics() {
  const { user } = useContext(UserContext);
  const { examYear } = useContext(ExamYearContext);

  const [schoolStatistics, setSchoolStatistics] = useState([]);
  const [numbersByAllSchools, setNumbersByAllSchools] = useState({
    approved: 0,
    notApproved: 0,
    students: 0,
    practiceOneResults: 0,
    practiceTwoResults: 0,
    approvedASP: 0,
    notApprovedASP: 0,
  });
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // getResultsUploadedCount({ page_size: 50 }).then((res) => {
    //   console.log('res', res);
    // });

    if (examYear?.uuid) {
      setLoading(true);
      getSchoolStatistics({ exam_year: examYear.uuid, page_size: 50 })
        .then((res) => {
          setSchoolStatistics(res.results);

          let approved = 0;
          let notApproved = 0;
          let students = 0;
          let practiceOneResults = 0;
          let practiceTwoResults = 0;
          let approvedASP = 0;
          let notApprovedASP = 0;
          let uploadedCity = 0;
          let uploadedVillage = 0;
          let uploadedTotal = 0;

          res.results.forEach((school) => {
            approved = approved + school?.approved;
            notApproved = notApproved + school?.need_to_approve;
            students = students + school?.total_applications;
            practiceOneResults += school?.total_practice_1_results;
            practiceTwoResults += school?.total_practice_2_results;
            approvedASP = approvedASP + school?.approved_social;
            notApprovedASP = notApprovedASP + school?.need_to_approve_social;
            uploadedCity += school?.uploaded_1_1;
            uploadedVillage += school?.uploaded_1_2;
            uploadedTotal += school?.uploaded_1_total;
          });

          setNumbersByAllSchools({
            approved: approved,
            notApproved: notApproved,
            students: students,
            practiceOneResults: practiceOneResults,
            practiceTwoResults: practiceTwoResults,
            approvedASP: approvedASP,
            notApprovedASP: notApprovedASP,
            uploadedCity: uploadedCity,
            uploadedVillage: uploadedVillage,
            uploadedTotal: uploadedTotal,
          });
        })
        .finally(() => {
          setLoading(false);
        });

      getRegions().then((res) => {
        setRegions(res.results);
      });
    }
    return () => {};
  }, [examYear]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'school_id',
      },
      {
        Header: 'Название',
        accessor: 'name',
        // width: 160,
        // Cell: (props) => <span className='w-40'>{props.value}</span>,
      },
      {
        Header: 'Пробный-1: #учеников',
        accessor: 'total_practice_1_results',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
      {
        Header: 'Пробный-2: #учеников',
        accessor: 'total_practice_2_results',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
      {
        Header: 'Необработанные заявки',
        accessor: 'need_to_approve',
        Cell: (props) => (
          <div className='bg-indigo-200 text-center'>
            {props.value > 0 ? (
              <span className='font-bold text-red-600'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Принятые ученики',
        accessor: 'approved',
        Cell: (props) => (
          <div className='bg-indigo-200 text-center'>
            {props.value > 0 ? (
              <span className='font-bold text-green-600'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Всего поступающих',
        accessor: 'total_applications',
        Cell: (props) => (
          <div className='bg-indigo-200 text-center'>
            <span className='font-bold'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Необработанные заявки(ASP)',
        accessor: 'need_to_approve_social',
        Cell: (props) => (
          <div className=' bg-slate-200 text-center'>
            {props.value > 0 ? (
              <span className='font-bold text-red-600'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Принятые ученики(ASP, не одобрено)',
        accessor: 'approved_social_without_doc',
        Cell: (props) => (
          <div className=' bg-slate-200 text-center'>
            {props.value > 0 ? (
              <span className='font-bold text-blue-600'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Принятые ученики(ASP)',
        accessor: 'approved_social',
        Cell: (props) => (
          <div className=' bg-slate-200 text-center'>
            {props.value > 0 ? (
              <span className='font-bold text-green-600'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Всего поступающих(ASP)',
        accessor: 'total_applications_social',
        Cell: (props) => (
          <div className=' bg-slate-200 text-center'>
            <span className='font-bold'>{props.value}</span>
          </div>
        ),
      },
      // {
      //   Header: 'Необработанные заявки(ASP, не одобрено)',
      //   accessor: 'need_to_approve_social_without_doc',
      //   Cell: (props) => (
      //     <div className=' bg-blue-100 text-center'>
      //       {props.value > 0 ? (
      //         <span className='font-bold text-red-600'>{props.value}</span>
      //       ) : (
      //         <span className=''>{props.value}</span>
      //       )}
      //     </div>
      //   ),
      // },
      {
        Header: 'Загружено(Город) 1 тур',
        accessor: 'uploaded_1_1',
        Cell: (props) => (
          <div className='bg-green-100 text-center'>{props.value}</div>
        ),
      },
      {
        Header: 'Загружено(Аудан) 1 тур',
        accessor: 'uploaded_1_2',
        Cell: (props) => (
          <div className='bg-green-100 text-center'>{props.value}</div>
        ),
      },
      {
        Header: 'Загружено(Всего) 1 тур',
        accessor: 'uploaded_1_total',
        Cell: (props) => (
          <div className='bg-green-100 text-center'>{props.value}</div>
        ),
      },
      {
        Header: 'Отсутствовали',
        accessor: '',
        Cell: (props) => (
          <div className='bg-red-100 text-center'>
            {props?.row?.original?.approved -
              props?.row?.original?.uploaded_1_total}
          </div>
        ),
      },
    ],
    [],
  );

  return !loading ? (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Принятые ученики:{' '}
          <span className='font-bold text-green-600'>
            {numbersByAllSchools?.approved}
          </span>
        </div>
        <div>
          Необработанные заявки:{' '}
          <span className='font-bold text-red-600'>
            {numbersByAllSchools?.notApproved}
          </span>
        </div>
        <div>
          Всего поступающих:{' '}
          <span className='font-bold'>{numbersByAllSchools?.students}</span>
        </div>
      </div>
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Принятые ученики(ASP):{' '}
          <span className='font-bold text-green-600'>
            {numbersByAllSchools?.approvedASP}
          </span>
        </div>
        <div>
          Необработанные заявки(ASP):{' '}
          <span className='font-bold text-red-600'>
            {numbersByAllSchools?.notApprovedASP}
          </span>
        </div>
        <div>
          Всего поступающих(ASP) :{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.notApprovedASP +
              numbersByAllSchools?.approvedASP}
          </span>
        </div>
      </div>
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Пробный-1: #учеников:{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.practiceOneResults}
          </span>
        </div>
        <div>
          Пробный-2: #учеников:{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.practiceTwoResults}
          </span>
        </div>
      </div>
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Загружено(<span className='font-semibold'>Город</span>) 1 тур:{' '}
          <span className='font-bold'>{numbersByAllSchools?.uploadedCity}</span>
        </div>
        <div>
          Загружено(<span className='font-semibold'>Аудан</span>) 1 тур:{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.uploadedVillage}
          </span>
        </div>
        <div>
          Загружено(<span className='font-semibold'>Всего</span>) 1 тур:{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.uploadedTotal}
          </span>
        </div>
      </div>
      <div>
        <Select
          className={`h-6 w-48 ${
            user?.roles?.some((role) => role?.code.includes('RC')) && 'hidden'
          }`}
          placeholder={'Регион'}
          value={selectedRegion}
          options={[
            { label: 'Все регионы', value: '' },
            ...regions.map((region, i) => ({
              label: region?.name,
              value: region?.uuid,
            })),
          ]}
          onChange={(e) => {
            setSelectedRegion(e.value);
            getSchoolStatistics({
              exam_year: examYear?.uuid,
              page_size: 50,
              region: e.value,
            }).then((res) => {
              setSchoolStatistics(res.results);

              let approved = 0;
              let notApproved = 0;
              let students = 0;
              let practiceOneResults = 0;
              let approvedASP = 0;
              let notApprovedASP = 0;

              res.results.forEach((school) => {
                approved = approved + school?.approved;
                notApproved = notApproved + school?.need_to_approve;
                students = students + school?.total_applications;
                practiceOneResults =
                  practiceOneResults + school?.total_practice_1_results;
                approvedASP = approvedASP + school?.approved_social;
                notApprovedASP =
                  notApprovedASP + school?.need_to_approve_social;
              });

              setNumbersByAllSchools({
                approved: approved,
                notApproved: notApproved,
                students: students,
                practiceOneResults: practiceOneResults,
                approvedASP: approvedASP,
                notApprovedASP: notApprovedASP,
              });
            });
          }}
        />
      </div>
      <Table columns={columns} data={schoolStatistics} />
    </div>
  ) : (
    <SimpleLoader className='h-16' />
  );
}
