import React, { useRef, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import AvatarEditor from 'react-avatar-editor';

const UpdateAvatar = ({ onClose, setAvatar, setBody }) => {
  const [t] = useTranslation();

  const [userPhoto, setUserPhoto] = useState(null);
  const [editor, setEditor] = useState(null);
  const fileRef = useRef(null);
  const [scale, setScale] = useState(1);
  // const [showMsg, setShowMsg] = useState(false);

  const setEditorRef = (editor) => setEditor(editor);

  const saveAvatar = async () => {
    const img = editor.getImageScaledToCanvas();

    img.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileRef?.current?.files[0].name, {
          type: 'image',
        });
        setAvatar(file);
        setBody((body) => ({
          ...body,
          avatar_photo: file,
        }));
      }
    });

    onClose();
  };

  const handleScale = (e) => {
    const scaleEvent = parseFloat(e.target.value);
    setScale(scaleEvent);
  };

  return (
    <>
      <AvatarEditor
        ref={setEditorRef}
        image={userPhoto && userPhoto}
        // width={250}
        // height={250}
        className='w-full'
        border={50}
        color={[255, 255, 255, 0.6]} // RGBA
        scale={scale}
        rotate={0}
      />

      <div className='flex items-center justify-center gap-2'>
        <label>Масштаб</label>
        <input
          name='scale'
          type='range'
          onChange={(e) => handleScale(e)}
          min={'0.5'}
          max='2'
          step='0.02'
          defaultValue='1'
        />
      </div>

      <div className='mt-4 flex flex-row justify-end gap-4'>
        <Button
          type='button'
          text={t('buttons.select')}
          onClick={() => fileRef.current.click()}
        />
        <input
          hidden
          type='file'
          accept='image/*'
          ref={fileRef}
          onChange={(e, d) => {
            setUserPhoto(e.target.files[0]);
          }}
        />

        <Button
          disabled={!fileRef?.current?.files[0]}
          type='button'
          text={t('buttons.save')}
          onClick={saveAvatar}
          color='success'
        />
      </div>
    </>
  );
};

export default UpdateAvatar;
