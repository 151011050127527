import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import UpdateAvatar from './UpdateAvatar';
import Attention from './Attention';

const AvatarModal = ({ open, setOpen, setAvatar, setBody }) => {
  const [t] = useTranslation();

  const [index, setIndex] = useState(0);
  // const [showMsg, setShowMsg] = useState(false);

  const onClose = () => {
    setOpen(false);
    setIndex(0);
  };

  return (
    <CustomDialog
      isOpen={open}
      setIsOpen={setOpen}
      closeModal={onClose}
      headerText={t('signup.upload_photo')}
    >
      <div className='flex flex-col gap-2 sm:p-4'>
        {index ? (
          <UpdateAvatar
            setIndex={setIndex}
            onClose={onClose}
            setAvatar={setAvatar}
            setBody={setBody}
          />
        ) : (
          <Attention setIndex={setIndex} />
        )}
      </div>
    </CustomDialog>
  );
};

export default AvatarModal;
