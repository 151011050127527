import bilAPI from '../api';

export const getMyPlace = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/account_result/my_place', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getMyResults = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/account_result/my_results', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getListToApprove = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/account_result/list_to_approve', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getResults = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/account_result/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchResult = (resultUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/results/account_result/${resultUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadResultsToCheck = (file, day, location_type) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/results/upload_results_file/', { file, day, location_type })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadResultsExcel = (file, round, is_asp) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/results/upload_results_file_xlsx/', { file, round, is_asp })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolPassScore = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/school_pass_score/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getRejectReasons = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/reason/')
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getResultsStats = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/results_stats_api/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
