import bilAPI from '../api';

export const getMyPlaceSecret = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/7315555e-50a6-48b5-8b79-b0063687c315/my_place', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getMyResultsSecret = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/7315555e-50a6-48b5-8b79-b0063687c315/my_results', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getResultsSecret = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/7315555e-50a6-48b5-8b79-b0063687c315/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
