import React, { useContext, useEffect, useState } from 'react';
import {
  getListToApprove,
  getRejectReasons,
  patchResult,
} from '../../services/results/resultsService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { Button } from '../../components/forms/Buttons/Button';
import {
  ChevronDownIcon,
  EyeIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import Select from '../../components/forms/Select/Select';
import useSnackbar from '../../utils/hooks/useSnackbar';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../utils/contexts/UserContext';
import ExportToBilOrgModal from './ExportToBilOrgModal';
import { getAcceptanceStats } from '../../services/acceptance/acceptanceService';
import { useParams } from 'react-router-dom';

const PAGE_SIZE = 50;
const ACCEPTED_OPTIONS = [
  {
    label: 'Қабылданды / Принят',
    value: 3,
  },
  {
    label: 'Оқудан бас тартты / Отказался учиться',
    value: 2,
  },
  // {
  //   label: 'Телефон не брал',
  //   value: 1,
  // },
  {
    label: 'Белгісіз / Неизвестно',
    value: 0,
  },
];

export default function AcceptedStudents() {
  const { uuid } = useParams();
  const { addSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [reasons, setReasons] = useState([]);
  const [studentsResults, setStudentsResults] = useState([]);
  const [acceptanceStats, setAcceptanceStats] = useState({});
  const [declinedKz, setDeclinedKz] = useState(0);
  const [declinedRu, setDeclinedRu] = useState(0);
  const [passedRu, setPassedRu] = useState(0);
  const [passedKz, setPassedKz] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [studentToEdit, setStudentToEdit] = useState(null);

  useEffect(() => {
    if (user?.school?.school_id || uuid) {
      setLoading(true);
      getAcceptanceStats({ school: uuid }).then((res) => {
        setAcceptanceStats(res);
      });
      getListToApprove({
        page: 1,
        page_size: PAGE_SIZE,
        school: uuid,
      })
        .then((res) => {
          setDeclinedKz(
            res?.results?.filter(
              (r) =>
                r.acceptance_status === 2 && r.application?.language === 'K',
            )?.length,
          );
          setDeclinedRu(
            res?.results?.filter(
              (r) =>
                r.acceptance_status === 2 && r.application?.language === 'R',
            )?.length,
          );
          setPassedRu(
            res?.results?.filter(
              (r) => r.status === 2 && r.application?.language === 'R',
            )?.length,
          );
          setPassedKz(
            res?.results?.filter(
              (r) => r.status === 2 && r.application?.language === 'K',
            )?.length,
          );
          setStudentsResults(res.results);
          setCount(res.count);
        })
        .finally(() => {
          setLoading(false);
        });

      getRejectReasons().then((res) => {
        setReasons(
          res.results.map((v) => ({ label: v.name, value: v.uuid })).reverse(),
        );
      });
    }

    return () => {};
  }, [user, uuid]);

  const addStudents = () => {
    setLoadingMore(true);
    getListToApprove({
      page: page + 1,
      page_size: PAGE_SIZE,
      school: uuid,
    })
      .then((res) => {
        setStudentsResults(studentsResults?.concat(res?.results));
        setDeclinedKz(
          (p) =>
            p +
            res?.results?.filter(
              (r) =>
                r.acceptance_status === 2 && r.application?.language === 'K',
            )?.length,
        );
        setDeclinedRu(
          (p) =>
            p +
            res?.results?.filter(
              (r) =>
                r.acceptance_status === 2 && r.application?.language === 'R',
            )?.length,
        );
        setPassedRu(
          (p) =>
            p +
            res?.results?.filter(
              (r) => r.status === 2 && r.application?.language === 'R',
            )?.length,
        );
        setPassedKz(
          (p) =>
            p +
            res?.results?.filter(
              (r) => r.status === 2 && r.application?.language === 'K',
            )?.length,
        );

        setPage(page + 1);
      })
      .finally(() => setLoadingMore(false));
  };

  const receiveStudentDocuments = async (
    resultUuid,
    acceptanceStatus,
    reason,
  ) => {
    // console.log(isAccepted, reason);
    if (acceptanceStatus === 2 && reason === null) {
      addSnackbar('Себебін таңдаңыз', 'info');
      const updatedResults = [...studentsResults];
      const resultToUpdate = updatedResults.find((r) => r.uuid === resultUuid);
      resultToUpdate.acceptance_status = 2;
      setStudentsResults(updatedResults);
    } else {
      let body;
      if (acceptanceStatus === 2) {
        body = {
          acceptance_status: acceptanceStatus,
          reason: reason,
        };
      } else {
        body = {
          acceptance_status: acceptanceStatus,
        };
      }

      await patchResult(resultUuid, body)
        .then((res) => {
          const updatedResults = [...studentsResults];
          const resultToUpdate = updatedResults.find(
            (r) => r.uuid === resultUuid,
          );
          if (
            resultToUpdate.acceptance_status === 2 &&
            res.acceptance_status !== 2
          ) {
            if (resultToUpdate.application.language === 'K') {
              setDeclinedKz((p) => p - 1);
            } else {
              setDeclinedRu((p) => p - 1);
            }
          } else if (
            resultToUpdate.acceptance_status === 2 &&
            !resultToUpdate.reason &&
            res.acceptance_status === 2
          ) {
            if (resultToUpdate.application.language === 'K') {
              setDeclinedKz((p) => p + 1);
            } else {
              setDeclinedRu((p) => p + 1);
            }
          }

          resultToUpdate.acceptance_status = res.acceptance_status;
          resultToUpdate.reason = res.reason;
          setStudentsResults(updatedResults);
          addSnackbar('Успешно', 'success');
          getAcceptanceStats().then((res) => {
            setAcceptanceStats(res);
          });
        })
        .catch((err) => {
          // console.log('err', err);
          if (err?.response?.data?.code === '001') {
            addSnackbar(
              'Пожалуйста, проверьте ученика с баллами выше',
              'error',
            );
          } else if (err?.response?.data?.code === '002') {
            addSnackbar('Добавьте причину', 'error');
          } else if (err?.response?.data?.code === '003') {
            addSnackbar('Эти значения нельзя поменять', 'error');
          } else if (err?.response?.data?.code === '004') {
            addSnackbar(
              'не редактируется, пока олимпиадный статус не присвоется ученикам с одинаковыми баллами',
              'error',
            );
          } else if (err?.response?.data?.code === '005') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников из резерва',
              'error',
            );
          } else if (err?.response?.data?.code === '006') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников из резерва для Русской группы',
              'error',
            );
          } else if (err?.response?.data?.code === '007') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников из резерва для Казахской группы',
              'error',
            );
          } else if (err?.response?.data?.code === '008') {
            addSnackbar(
              'Администрация закрыла доступ для приема документов учеников',
              'error',
            );
          } else if (err?.response?.data?.code === '009') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников',
              'error',
            );
          } else if (err?.response?.data?.code === '010') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников для Русской группы',
              'error',
            );
          } else if (err?.response?.data?.code === '011') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников для Казахской группы',
              'error',
            );
          } else {
            addSnackbar('Ошибка', 'error');
          }
        });
    }
  };

  // const updateOlympiadStatus = async (resultUuid, status) => {
  //   const body = {
  //     olympiad_status: status,
  //   };

  //   await patchResult(resultUuid, body)
  //     .then((res) => {
  //       const updatedResults = [...studentsResults];
  //       const resultToUpdate = updatedResults.find(
  //         (r) => r.uuid === resultUuid,
  //       );
  //       resultToUpdate.olympiad_status = res.olympiad_status;
  //       setStudentsResults(updatedResults);
  //       addSnackbar('Успешно', 'success');
  //     })
  //     .catch((err) => {
  //       addSnackbar('Ошибка', 'error');
  //     });
  // };

  return (
    <div>
      <CommentEditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        studentToEdit={studentToEdit}
        studentsResults={studentsResults}
        setStudentsResults={setStudentsResults}
      />
      {loading ? (
        <div className='h-full w-full p-12'>
          <SimpleLoader className={'h-14 w-14'} />
        </div>
      ) : studentsResults?.length ? (
        <>
          <div className='mb-4 flex flex-row justify-end'>
            <div className='flex flex-col'>
              {!uuid && <ExportToBilOrgModal />}
              <p className='flex flex-col items-end'>
                {!acceptanceStats.max_accept_ru &&
                acceptanceStats.max_accept_ru !==
                  acceptanceStats.max_accept_kz ? (
                  <>
                    <span>{`Лимит: ${acceptanceStats.max_accept_kz} ${
                      acceptanceStats.extra_students
                        ? `(+${acceptanceStats.extra_students})`
                        : ''
                    }`}</span>
                    <span>{`Принято: ${acceptanceStats.accepted_kz}`}</span>
                    <span>{`Отказались: ${acceptanceStats.declined_kz}`}</span>
                  </>
                ) : (
                  <>
                    <span>{`Лимит (kz): ${acceptanceStats.max_accept_kz} ${
                      acceptanceStats.extra_students
                        ? `(+${acceptanceStats.extra_students})`
                        : ''
                    }`}</span>
                    <span>{`Лимит (ru): ${acceptanceStats.max_accept_ru} ${
                      acceptanceStats.extra_students
                        ? `(+${acceptanceStats.extra_students})`
                        : ''
                    }`}</span>
                    <span>{`Принято (kz): ${acceptanceStats.accepted_kz}`}</span>
                    <span>{`Принято (ru): ${acceptanceStats.accepted_ru}`}</span>
                    <span>{`Отказались (kz): ${acceptanceStats.declined_kz}`}</span>
                    <span>{`Отказались (ru): ${acceptanceStats.declined_ru}`}</span>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className='max-h-[70vh] items-center gap-4 overflow-x-auto'>
            <table className='w-full table-auto'>
              <thead className='sticky top-0 z-10 border-b bg-gray-800 text-white'>
                <tr>
                  <td className='p-2 font-medium uppercase'>#</td>
                  <td className='border-l p-2 font-medium uppercase'>ЖСН</td>
                  <td className='border-l p-2 font-medium uppercase'>Аты</td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Жөні
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Телефон
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Топ
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    ӘОТ
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    1000 бала
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Math correct
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Math wrong
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Math Point
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Lang correct
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Lang wrong
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Lang Point
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Дұрыс
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Қате
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    1 тур
                  </td>
                  {/* <td className='border-l py-2 text-center font-medium uppercase'>
                    Олимпиада
                  </td>
                  <td className='w-auto border-l py-2 text-center font-medium uppercase'>
                    1 тур(+)2 тур
                  </td> */}
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Статус
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Құжат
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Cебеп
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Коммент
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'></td>
                </tr>
              </thead>
              <tbody className=''>
                {studentsResults.map((student, index) => {
                  let is_avaible = false;
                  // const is_next = !studentsResults.some(
                  //   (r) =>
                  //     student.status !== 2 &&
                  //     r.acceptance_status === 0 &&
                  //     r.status !== 2 &&
                  //     (student.result < r.result ||
                  //       (student.result === r.result &&
                  //         student.math_points < r.math_points)),
                  // );
                  const is_next = true;

                  const is_passed =
                    student.status === 2 || student.acceptance_status === 3;
                  const is_rus_reserve_avaible =
                    acceptanceStats.max_accept_ru &&
                    student.application?.language === 'R' &&
                    passedRu +
                      declinedRu +
                      acceptanceStats.extra_students -
                      1 >=
                      index;
                  const is_kaz_reserve_avaible =
                    acceptanceStats.max_accept_ru &&
                    student.application?.language === 'K' &&
                    passedKz +
                      declinedKz +
                      acceptanceStats.extra_students -
                      1 >=
                      index;
                  const is_reserve_avaible =
                    passedKz +
                      passedRu +
                      declinedKz +
                      declinedRu +
                      acceptanceStats.extra_students -
                      1 >=
                    index;

                  is_avaible =
                    (is_passed ||
                      is_rus_reserve_avaible ||
                      is_kaz_reserve_avaible ||
                      is_reserve_avaible) &&
                    is_next;

                  if (
                    student.acceptance_status === 2 &&
                    is_avaible &&
                    student.reason
                  ) {
                    const is_forbidden =
                      acceptanceStats.extra_students +
                        acceptanceStats.max_accept_kz +
                        acceptanceStats.max_accept_ru <=
                      acceptanceStats.accepted_kz + acceptanceStats.accepted_ru;
                    const is_forbidden_kz =
                      acceptanceStats.max_accept_ru &&
                      student.application?.language === 'K' &&
                      acceptanceStats.extra_students +
                        acceptanceStats.max_accept_kz <=
                        acceptanceStats.accepted_kz;
                    const is_forbidden_ru =
                      acceptanceStats.max_accept_ru &&
                      student.application?.language === 'R' &&
                      acceptanceStats.extra_students +
                        acceptanceStats.max_accept_ru <=
                        acceptanceStats.accepted_ru;
                    is_avaible = !(
                      is_forbidden |
                      is_forbidden_kz |
                      is_forbidden_ru
                    );
                  }
                  // is_avaible = true;
                  return (
                    <tr
                      key={index}
                      className='border-b last:border-b-0 hover:bg-sheet-gray'
                    >
                      <td className='border-r p-2'>{index + 1}</td>

                      <td className='border-r p-2'>
                        {student?.application?.iin}
                      </td>
                      <td className='border-r p-2'>
                        {student?.application?.first_name}
                      </td>
                      <td className='border-r p-2'>
                        {student?.application?.last_name}
                      </td>

                      <td className='border-r text-center'>
                        {student?.application?.parent_phone_number}
                      </td>
                      <td className='border-r px-2 text-center'>
                        {student?.application?.language === 'K' ? (
                          <span className=''>Қазақша</span>
                        ) : (
                          <span className=''>Русский</span>
                        )}
                      </td>
                      <td className='border-r px-2 text-center'>
                        {student?.is_asp ? (
                          <span className=''>Иә</span>
                        ) : (
                          <span className=''>Жоқ</span>
                        )}
                      </td>
                      <td className='border-r px-2 text-center'>
                        {student?.is_myn_bala ? (
                          <span className=''>1000 бала</span>
                        ) : (
                          <span className=''></span>
                        )}
                      </td>
                      <td className='border-r text-center text-green-600'>
                        {student?.math_correct}
                      </td>
                      <td className='border-r text-center text-red-600'>
                        {student?.math_wrong}
                      </td>
                      <td className='border-r bg-gray-800 text-center text-white'>
                        {student?.math_points}
                      </td>
                      <td className='border-r text-center text-green-600'>
                        {student?.language_correct}
                      </td>
                      <td className='border-r text-center text-red-600'>
                        {student?.language_wrong}
                      </td>
                      <td className='border-r bg-gray-800 text-center text-white'>
                        {student?.language_points}
                      </td>
                      <td className='border-r text-center text-green-600'>
                        {student?.total_correct}
                      </td>
                      <td className='border-r text-center text-red-600'>
                        {student?.total_wrong}
                      </td>
                      <td className='border-r bg-gray-800 text-center text-white'>
                        {student?.result}
                      </td>
                      {/* <td className='border-r text-center'>
                      <Select
                        className='w-40 px-1 2xl:w-auto'
                        isSearchable={false}
                        options={[
                          {
                            label: 'Иә / Да',
                            value: 2,
                          },
                          {
                            label: 'Жоқ / Нет',
                            value: 1,
                          },

                          {
                            label: 'Ақпарат жоқ / Нет инфо',
                            value: 0,
                          },
                        ]}
                        value={student?.olympiad_status}
                        onChange={(e) =>
                          updateOlympiadStatus(student?.uuid, e.value)
                        }
                      />
                    </td>
                    <td className='border-r bg-gray-500 text-center text-white'>
                      {student?.total_points}
                    </td> */}
                      <td className='border-r text-center'>
                        <div className='text-center'>
                          {student?.status === 0 && (
                            <span className='font-semibold text-red-600'>
                              Өтпеді
                            </span>
                          )}
                          {student?.status === 1 && (
                            <span className=''>Резерв</span>
                          )}
                          {student?.status === 2 && (
                            <span className='font-semibold text-green-600'>
                              Өтті
                            </span>
                          )}
                        </div>
                      </td>
                      <td
                        className={`border-r text-center ${
                          student?.acceptance_status === 0
                            ? 'bg-yellow-200'
                            : student?.acceptance_status === 3
                            ? 'bg-green-200'
                            : 'bg-red-200'
                        }`}
                      >
                        {uuid || !is_avaible ? (
                          <span className='break-normal px-2'>
                            {
                              ACCEPTED_OPTIONS.find(
                                (v) => v.value === student?.acceptance_status,
                              )?.label
                            }
                          </span>
                        ) : (
                          <Select
                            className='w-40 px-1 2xl:w-auto'
                            isSearchable={false}
                            options={ACCEPTED_OPTIONS}
                            value={student?.acceptance_status}
                            onChange={(e) =>
                              receiveStudentDocuments(
                                student?.uuid,
                                e.value,
                                student?.reason,
                              )
                            }
                          />
                        )}
                      </td>

                      <td className='border-r text-center'>
                        {uuid ? (
                          <span className='break-normal px-2'>
                            {
                              reasons.find((v) => v.value === student?.reason)
                                ?.label
                            }
                          </span>
                        ) : (
                          <Select
                            className='w-40 px-1 2xl:w-auto'
                            isSearchable={false}
                            isDisabled={student?.acceptance_status !== 2}
                            options={reasons}
                            value={student?.reason}
                            onChange={(e) =>
                              receiveStudentDocuments(student?.uuid, 2, e.value)
                            }
                          />
                        )}
                      </td>
                      <td className='border-r px-2'>
                        <div className='flex flex-row items-center justify-around'>
                          <p className='w-40 break-words text-sm'>
                            {student?.comment}
                          </p>

                          {!uuid && (
                            <button
                              type='button'
                              className='items-center rounded-md p-1 hover:bg-blue-600 hover:text-white'
                              onClick={() => {
                                setStudentToEdit(student);
                                setModalOpen(true);
                              }}
                            >
                              <PencilIcon className='h-4 ' />
                            </button>
                          )}
                        </div>
                      </td>
                      <td>
                        <a
                          href={`/results/${student?.application?.uuid}/pass`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Button
                            type='button'
                            Icon={EyeIcon}
                            className=''
                            // onClick={() => {
                            //   navigate(
                            //     `/results/${student?.application?.uuid}/pass`,
                            //   );
                            // }}
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {studentsResults?.length < count && (
              <div className='mt-2 text-center'>
                <Button
                  type='button'
                  text='Показать больше'
                  loading={loadingMore}
                  Icon={ChevronDownIcon}
                  onClick={() => {
                    if (count > page * PAGE_SIZE) {
                      addStudents();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='text-center text-xl italic text-secondary'>
          нет данных
        </div>
      )}
    </div>
  );
}

function CommentEditModal({
  modalOpen,
  setModalOpen,
  studentToEdit,
  studentsResults,
  setStudentsResults,
}) {
  const { addSnackbar } = useSnackbar();
  const { register, handleSubmit, setValue } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue('comment', studentToEdit?.comment);

    return () => {};
  }, [setValue, studentToEdit]);

  const onSubmit = async (data) => {
    setLoading(true);
    const body = {
      comment: data.comment,
    };

    await patchResult(studentToEdit?.uuid, body)
      .then((res) => {
        const updatedResults = [...studentsResults];
        const resultToUpdate = updatedResults.find(
          (r) => r.uuid === studentToEdit?.uuid,
        );
        resultToUpdate.comment = res.comment;
        setStudentsResults(updatedResults);
        setModalOpen(false);
      })
      .catch((err) => {
        addSnackbar('Ошибка', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Комментарий'}
    >
      <form className='flex flex-col gap-4' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <textarea
            id='about_2'
            name='about_2'
            rows={3}
            className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-slate-200 sm:text-sm'
            defaultValue={''}
            {...register('comment')}
          />
        </div>
        <Button
          loading={loading}
          type='submit'
          text='Изменить'
          onClick={() => {}}
        />
      </form>
    </CustomDialog>
  );
}
