import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import Select from '../../components/forms/Select/Select';
import { getSocialDocumentsTypes } from '../../services/socialDocs/socialDocsTypes';

export default function VulnerableGroup({
  applicant,
  setBody,
  nextPage,
  setVulnerableGroupPartCompleted,
  setVulnerableGroup,
}) {
  const [t, i18n] = useTranslation();
  const [selectedAnswer, setSelectedAnswer] = useState(
    applicant?.social_doc_type ? 'yes' : null,
  );
  const [selected, setSelected] = useState(applicant?.social_doc_type);
  const [vulnerableGroups, setVulnerableGroups] = useState([]);

  const plans = [
    {
      name: t('signup.yes'),
      value: 'yes',
    },
    {
      name: t('signup.no'),
      value: 'no',
    },
  ];

  useEffect(() => {
    getSocialDocumentsTypes().then((res) => {
      setVulnerableGroups(res.results);
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (selectedAnswer === 'no') {
      setBody((body) => ({
        ...body,
        social_doc_type: null,
      }));
      setVulnerableGroup(null);
    }

    return () => {};
  }, [selectedAnswer, setBody, setVulnerableGroup]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='rounded-md border border-blue-500 p-2'>
        {/* <span className='text-red-600'>*</span> */}
        <p className='text-sm text-red-600'>{t('signup.law')}</p>
      </div>
      <div>
        <h3 className='mb-2 text-base font-semibold italic'>
          {t('signup.question')}
        </h3>
        <RadioGroup value={selectedAnswer} onChange={setSelectedAnswer}>
          <RadioGroup.Label className='sr-only'>Server size</RadioGroup.Label>
          <div className='space-y-2'>
            {plans.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan?.value}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                      : ''
                  }
                  ${
                    checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'
                  }
                    relative flex cursor-pointer rounded-lg py-2 px-4  shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className='flex w-full items-center justify-between'>
                      <div className='flex  items-center'>
                        <div className='text-sm'>
                          <RadioGroup.Label
                            as='p'
                            className={`font-medium  ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            {plan?.name}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked && (
                        <div className='shrink-0 text-white'>
                          <CheckIcon className='h-6 w-6' />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      {selectedAnswer === 'yes' && (
        <>
          <div>
            <div className='mt-12 w-full'>
              <Select
                label={
                  <h3 className='mb-2 text-base font-semibold italic'>
                    {t('signup.question_1')}
                  </h3>
                }
                isSearchable={false}
                className=''
                value={applicant?.social_doc_type}
                options={[
                  // { label: t('signup.not_in_category'), value: null },
                  ...vulnerableGroups.map((group, i) => ({
                    label:
                      `${i + 1}. ` +
                      (i18n.language === 'kk' ? group?.name : group?.name_ru),
                    value: group?.uuid,
                    docs_list: { kz: group?.files, ru: group?.files_ru },
                    data: group,
                  })),
                ]}
                // placeholder={t('announcement.select_positions')}
                // onChange={(e) => field.onChange(e.value)}
                onChange={(e) => {
                  setSelected(e);
                  setBody((body) => ({
                    ...body,
                    social_doc_type: e.value,
                  }));
                  setVulnerableGroup(e.data);
                }}
              />
            </div>
          </div>
          {selected?.value && (
            <div>
              <span className='mb-2 font-semibold'>
                {t('signup.docs_list')}
              </span>
              <ul>
                {i18n.language === 'kk'
                  ? selected?.docs_list?.kz?.map((file_name, i) => (
                      <li key={i}>{`${i + 1}. ` + file_name}</li>
                    ))
                  : selected?.docs_list?.ru?.map((file_name, i) => (
                      <li key={i}>{`${i + 1}. ` + file_name}</li>
                    ))}
              </ul>
            </div>
          )}
          {selected?.value && (
            <div className='rounded-md border border-red-500 bg-red-200  p-2'>
              {/* <ExclamationCircleIcon className=' w-6' />{' '} */}
              <p className='text-sm font-semibold'>
                {t('signup.docs_warning')}
              </p>
            </div>
          )}
        </>
      )}

      <div className='mt-6'>
        <div className='flex flex-row justify-end'>
          {/* <Button type='button' text='Back' color='secondary' /> */}
          <Button
            type='submit'
            text={t('buttons.save')}
            onClick={() => {
              if (selected || selectedAnswer === 'no') {
                setVulnerableGroupPartCompleted(true);
                nextPage(5);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
