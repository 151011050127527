import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import { Document, Page } from 'react-pdf';

export default function DocumentUpload({
  applicant,
  setBody,
  nextPage,
  setDocsPartCompleted,
}) {
  // const [doc, setDoc] = useState(null);
  const [t] = useTranslation();
  const fileRef = useRef(null);

  return (
    <div className='flex h-full flex-col justify-between'>
      <div>
        <div className='mb-2 flex flex-row items-center'>
          <h3 className='mr-4 text-lg font-semibold'>
            {t('signup.birth_photo')}
          </h3>
          <div className=''>
            <Button
              text={t('signup.upload_docs')}
              onClick={() => fileRef.current.click()}
            />
            <input
              hidden
              type='file'
              accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps'
              ref={fileRef}
              onChange={(e, d) => {
                // setDoc(e.target.files[0]);
                setBody((body) => ({
                  ...body,
                  document: e.target.files[0],
                }));
              }}
            />
          </div>
        </div>
        <div>
          {applicant?.document &&
            (applicant?.document?.type?.startsWith('image/') ? (
              <img
                className='h-[24rem]'
                src={URL.createObjectURL(applicant?.document)}
                alt='doc'
              />
            ) : (
              <div className='h-[24rem]'>
                <Document file={applicant?.document}>
                  <Page pageNumber={1} renderTextLayer={false} height={400} />
                </Document>
              </div>
            ))}
        </div>
      </div>
      <div className=''>
        <div className='mt-6 flex flex-row justify-end'>
          {/* <Button type='button' text='Back' color='secondary' /> */}
          <Button
            type='submit'
            text={t('buttons.save')}
            onClick={() => {
              if (applicant?.document) {
                setDocsPartCompleted(true);
                nextPage(3);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
