import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import Input from '../../components/forms/Input/Input';
import useAuth from '../../utils/hooks/useAuth';
import { UserContext } from '../../utils/contexts/UserContext';

export default function Login() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const {
    // formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const { user } = useContext(UserContext);
  const { loginUser } = useAuth();
  // const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    localStorage.clear();

    await loginUser(data).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (user?.uuid) {
      if (
        user?.roles?.some(
          (role) =>
            role.code === 'TRADMIN' ||
            role.code === 'SA' ||
            role?.code === 'SSA' ||
            role?.code?.includes('RC'),
        )
      ) {
        navigate({
          pathname: `/school-registration-statistics`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === 'VP')) {
        navigate({
          pathname: `/applicants`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === 'STD')) {
        navigate({
          pathname: `/student/profile`,
          state: { user: user },
        });
      }
      // addSnackbar('Пожалуйста, выйдите из системы', 'info');
    }

    return () => {};
  }, [user, navigate]);

  return (
    <div className='mt-28 flex justify-center'>
      <div className='flex flex-col gap-2 rounded-md border bg-white p-8'>
        {/* <div className='flex flex-row justify-between'> */}
        {/* <div>
          <p className='px-14 py-2 text-xl font-bold'>Bilemtihan</p>
        </div> */}
        {/* <div className='border rounded-lg px-8 py-3'>
                  <img src={logo} alt='logo' width='32' height='32' />
                </div> */}
        {/* </div> */}
        <form className='flex flex-col gap-2' onSubmit={handleSubmit(onSubmit)}>
          <Input
            className='w-64'
            label={'ID / Login'}
            type='text'
            required
            {...register('username', {
              required: true,
              maxLength: 64,
            })}
          />
          <Input
            className='w-64'
            label={t('welcome.password')}
            type='password'
            required
            {...register('password', {
              required: true,
              maxLength: 64,
            })}
          />
          <Button
            loading={loading}
            type='submit'
            color='primary'
            text={t('welcome.login')}
            className='mt-2'
          />
        </form>
        {false && (
          <>
            <div className='border-t'></div>
            <Button
              type='button'
              text={t('welcome.registration')}
              onClick={() => {
                navigate('/registration');
                // navigate('/qwerty');
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
