const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToKen');
  return refreshToken;
};

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem('accessToKen');
  return accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem('accessToKen', token);
};

const removeTokens = () => {
  localStorage.removeItem('refreshToKen');
  localStorage.removeItem('accessToKen');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  removeTokens,
};

export default TokenService;
