import { UserIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import { Document, Page } from 'react-pdf';

export default function Confirm({
  applicant,
  selectedBilSchool,
  selectedDistrict,
  selectedExamCenter,
  vulnerableGroup,
  apply,
  loading,
}) {
  const [t] = useTranslation();

  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col sm:flex-row'>
        <div className='flex flex-col items-center gap-2 sm:w-1/3'>
          {applicant?.avatar_photo ? (
            <img
              src={URL.createObjectURL(applicant?.avatar_photo)}
              alt='userPhoto'
              className='w-56'
            />
          ) : (
            <UserIcon className='h-24 w-24' />
          )}
          {applicant?.document &&
            (applicant?.document?.type?.startsWith('image/') ? (
              <img
                className='h-[24rem]'
                src={URL.createObjectURL(applicant?.document)}
                alt='doc'
              />
            ) : (
              <div className='h-[24rem]'>
                <Document file={applicant?.document}>
                  <Page pageNumber={1} renderTextLayer={false} height={400} />
                </Document>
              </div>
            ))}
        </div>
        <div className='items-center sm:w-2/3'>
          <div className='border border-gray-200'>
            <dl>
              <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.iin')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.iin}
                </dd>
              </div>
              <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.name')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.first_name + ' ' + applicant?.last_name}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.birth_date')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.birth_date &&
                    format(
                      moment(applicant?.birth_date).toDate(),
                      'dd / MM / yyyy',
                    )}
                </dd>
              </div>
              <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.bil_school')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.school && selectedBilSchool?.label}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.exam_center')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {selectedExamCenter?.label}
                </dd>
              </div>
              <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.study_lang')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.language === 'K' ? 'Қазақша' : 'Русский'}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.address')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.address + ' | ' + selectedDistrict?.label}
                </dd>
              </div>
              <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.parent_credentials')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.parent_first_name +
                    ' ' +
                    applicant?.parent_last_name}
                </dd>
              </div>
              <div className='bg-gray-50  px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.parent_phone')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.parent_phone_number}
                </dd>
              </div>
              <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.parent_phone_two')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.parent_phone_number_2}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.parent_email')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.email}
                </dd>
              </div>
              <div className='bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-right text-sm font-medium text-gray-500'>
                  {t('signup.which_school_from')}
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  {applicant?.current_school_name}
                </dd>
              </div>
              {vulnerableGroup?.name && (
                <div className='bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                  <dt className='text-right text-sm font-medium text-gray-500'>
                    {t('signup.asp')}
                  </dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                    {vulnerableGroup?.name}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-end font-semibold'>
        <input
          id='checkRU'
          name='checkRU'
          type='checkbox'
          checked={isChecked}
          value={isChecked}
          onChange={() => setIsChecked((v) => !v)}
          className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
        />
        <label htmlFor='checkRU' className={'ml-2 block text-red-600'}>
          {t('signup.agreement')}
        </label>
      </div>
      <div className='mt-2'>
        <div className='flex flex-row justify-end'>
          {/* <Button type='button' text='Back' color='secondary' /> */}
          <Button
            type='button'
            color='success'
            loading={loading}
            text={t('buttons.apply')}
            onClick={() => {
              if (isChecked) {
                apply();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
