import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SecretFirstDayResult({ studentResult }) {
  const [t] = useTranslation();

  return (
    <div className='flex flex-col  md:flex-row md:justify-between'>
      <dl className='my-4 w-full border md:w-2/3'>
        <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('student.admission_school')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {studentResult?.application?.school?.name}
          </dd>
        </div>
        <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('results.variant')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {studentResult?.exam_variant}
          </dd>
        </div>
        <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('results.correct')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {studentResult?.total_correct}
          </dd>
        </div>
        <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('results.wrong')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {studentResult?.total_wrong}
          </dd>
        </div>
        <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('results.points')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {studentResult?.result}
          </dd>
        </div>
        <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('results.passed_day_1')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {studentResult?.status === 0 && (
              <span className='font-semibold '>{t('results.not_passed')}</span>
            )}
            {studentResult?.status === 1 && (
              <span className='font-semibold '>{t('results.reserve')}</span>
            )}
            {studentResult?.status === 2 && (
              <span className='font-semibold text-green-600'>
                {t('results.passed')}
              </span>
            )}
          </dd>
        </div>
        <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('results.min_result_day_1')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {studentResult?.pass_score + ' '}
            {studentResult?.is_asp && <span className=''>(ӘОТ)</span>}{' '}
            {['002', '015', '051'].includes(
              studentResult?.application?.school?.school_id,
            ) &&
              (studentResult?.application?.language === 'K' ? (
                <span className=''>(KAZ)</span>
              ) : (
                <span className=''>(RUS)</span>
              ))}
          </dd>
        </div>
      </dl>
    </div>
  );
}
