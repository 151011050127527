import React, { useContext, useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import { getSchoolsAcceptanceStats } from '../../../services/ExtraAllowedStudents/ExtraAllowedStudentsService';
import { ExamYearContext } from '../../../utils/contexts/ExamYearContext';
import { Button } from '../../../components/forms/Buttons/Button';
import { EyeIcon } from '@heroicons/react/24/outline';

const AcceptedStudentsStats = () => {
  const { examYear } = useContext(ExamYearContext);

  const [schoolStatistics, setSchoolStatistics] = useState([]);

  useEffect(() => {
    getSchoolsAcceptanceStats({
      page_size: 100,
      exam_year: examYear?.uuid,
    }).then((res) => {
      setSchoolStatistics(res);
    });

    return () => {};
  }, [examYear?.uuid]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'school_id',
      },
      {
        Header: 'Название',
        accessor: 'name',
      },
      {
        Header: 'Accepted',
        accessor: 'accepted',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
      {
        Header: 'Declined',
        accessor: 'declined',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
      {
        Header: 'Limit',
        accessor: 'limit',
        Cell: (props) => (
          <div className='text-center'>
            {props.row.original.limit - props.row.original.extra_students}{' '}
            {!!props.row.original.extra_students &&
              `(+${props.row.original.extra_students})`}
          </div>
        ),
      },
      {
        Header: 'Extra',
        accessor: 'extra_students',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
      {
        Header: '',
        accessor: 'uuid',
        Cell: (props) => (
          <div className='text-center'>
            <a
              href={`/school-acceptance-stats/${props.row.original.uuid}`}
              target='_blank'
              rel='noreferrer'
            >
              <Button Icon={EyeIcon} className='max-h-6' />
            </a>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div className='flexflex-col gap-4'>
      <Table columns={columns} data={schoolStatistics} isSticky />
    </div>
  );
};

export default AcceptedStudentsStats;
