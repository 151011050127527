import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import Table from '../../components/Table/Table';
import { getRegions } from '../../services/catalog/catalog';
import { getSchoolResultsStatistics } from '../../services/schoolStatistics/schoolStatistics';
import { UserContext } from '../../utils/contexts/UserContext';
import ExportExcelExamStats from './ExportExcelExamStats';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import SimpleLoader from '../../components/Loader/SimpleLoader';

const TWOROUNDEXAM = [
  {
    Header: 'ID',
    accessor: 'school_id',
  },
  {
    Header: 'Название',
    accessor: 'name',
    width: 240,
    // Cell: (props) => <span className='w-40'>{props.value}</span>,
  },
  {
    Header: 'Участвовали в 1 туре (Регион)',
    headerClass: 'bg-gray-200',
    accessor: 'first_round_participated_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали в 1 туре (Центр)',
    headerClass: 'bg-gray-200',
    accessor: 'first_round_participated_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали в 1 туре (Всего)',
    headerClass: 'bg-gray-200',
    accessor: 'first_round_participated_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2024],
    // Header: 'Не участвовали в 1 туре (Регион)',
    Header: (props) => (
      <div className='relative text-center'>
        <span className='absolute -top-1 -right-6 rotate-12 rounded-lg bg-red-500 p-2 py-1 text-xs text-white'>
          new
        </span>
        Не участвовали в 1 туре (Регион)
      </div>
    ),
    headerClass: 'bg-gray-200',
    accessor: 'first_round_not_participated_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2024],
    // Header: 'Не участвовали в 1 туре (Центр)',
    Header: (props) => (
      <div className='relative text-center '>
        <span className='absolute -top-1 -right-6 rotate-12 rounded-lg bg-red-500 p-2 py-1 text-xs text-white'>
          new
        </span>
        Не участвовали в 1 туре (Центр)
      </div>
    ),
    headerClass: 'bg-gray-200',
    accessor: 'first_round_not_participated_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2024],
    // Header: 'Не участвовали в 1 туре (Всего)',
    Header: (props) => (
      <div className='relative text-center '>
        <span className='absolute -top-1 -right-6 rotate-12 rounded-lg bg-red-500 p-2 py-1 text-xs text-white'>
          new
        </span>
        Не участвовали в 1 туре (Всего)
      </div>
    ),
    headerClass: 'bg-gray-200',
    accessor: 'first_round_not_participated_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2024],
    // Header: 'Прошли 1 туре (Регион (регион))',
    Header: (props) => (
      <div className='relative flex h-full w-full flex-row text-center text-white'>
        <span className='absolute -top-1 -right-6 rotate-12 rounded-lg bg-red-500 p-2 py-1 text-xs'>
          new
        </span>
        Прошли 1 туре (Регион (регион))
      </div>
    ),
    headerClass: 'bg-blue-400 text-white',
    iconClass: 'text-white',
    accessor: 'first_round_passed_region_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2024],
    // Header: 'Прошли 1 туре (Регион (центр))',
    Header: (props) => (
      <div className='relative text-center'>
        <span className='absolute -top-1 -right-6 rotate-12 rounded-lg bg-red-500 p-2 py-1 text-xs text-white'>
          new
        </span>
        Прошли 1 туре (Регион (центр))
      </div>
    ),
    headerClass: 'bg-blue-400 text-white',
    iconClass: 'text-white',
    accessor: 'first_round_passed_region_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 1 туре (Регион)',
    headerClass: 'bg-blue-400 text-white',
    iconClass: 'text-white',
    accessor: 'first_round_passed_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 1 туре (Центр)',
    headerClass: 'bg-blue-400 text-white',
    iconClass: 'text-white',
    accessor: 'first_round_passed_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 1 туре (Всего)',
    headerClass: 'bg-blue-400 text-white',
    iconClass: 'text-white',
    accessor: 'first_round_passed_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2023],
    Header: 'Участвовали в 2 туре (Регион)',
    headerClass: 'bg-gray-200',
    accessor: 'second_round_participated_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2023],
    Header: 'Участвовали в 2 туре (Центр)',
    headerClass: 'bg-gray-200',
    accessor: 'second_round_participated_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2023],
    Header: 'Участвовали в 2 туре (Всего)',
    headerClass: 'bg-gray-200',
    accessor: 'second_round_participated_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2023],
    Header: 'Прошли 2 туре (Регион)',
    headerClass: 'bg-gray-200',
    accessor: 'second_round_passed_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2023],
    Header: 'Прошли 2 туре (Центр)',
    headerClass: 'bg-gray-200',
    accessor: 'second_round_passed_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    allow: [2023],
    Header: 'Прошли 2 туре (Всего)',
    headerClass: 'bg-gray-200',
    accessor: 'second_round_passed_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
];

// const ONEROUNDEXAM = [
//   {
//     Header: 'ID',
//     accessor: 'school_id',
//   },
//   {
//     Header: 'Название',
//     accessor: 'name',
//     width: 240,
//     // Cell: (props) => <span className='w-40'>{props.value}</span>,
//   },
//   {
//     Header: 'Участвовали (Регион)',
//     accessor: 'first_round_participated_region',
//     Cell: (props) => <div className='text-center'>{props.value}</div>,
//   },
//   {
//     Header: 'Участвовали (Центр)',
//     accessor: 'first_round_participated_center',
//     Cell: (props) => <div className='text-center'>{props.value}</div>,
//   },
//   {
//     Header: 'Участвовали (Всего)',
//     accessor: 'first_round_participated_total',
//     Cell: (props) => <div className='text-center'>{props.value}</div>,
//   },
//   {
//     Header: 'Прошли (Регион)',
//     accessor: 'first_round_passed_region',
//     Cell: (props) => <div className='text-center'>{props.value}</div>,
//   },
//   {
//     Header: 'Прошли (Центр)',
//     accessor: 'first_round_passed_center',
//     Cell: (props) => <div className='text-center'>{props.value}</div>,
//   },
//   {
//     Header: 'Прошли (Всего)',
//     accessor: 'first_round_passed_total',
//     Cell: (props) => <div className='text-center'>{props.value}</div>,
//   },
// ];

const SchoolExamStatistics = () => {
  const { user } = useContext(UserContext);
  const { examYear } = useContext(ExamYearContext);

  const [schoolStatistics, setSchoolStatistics] = useState([]);
  const [numbersByAllSchools, setNumbersByAllSchools] = useState({
    first_round_participated_region: 0,
    first_round_participated_center: 0,
    first_round_participated_total: 0,
    first_round_not_participated_region: 0,
    first_round_not_participated_center: 0,
    first_round_not_participated_total: 0,
    first_round_passed_region: 0,
    first_round_passed_region_region: 0,
    first_round_passed_region_center: 0,
    first_round_passed_center: 0,
    first_round_passed_total: 0,
    second_round_participated_region: 0,
    second_round_participated_center: 0,
    second_round_participated_total: 0,
    second_round_passed_region: 0,
    second_round_passed_center: 0,
    second_round_passed_total: 0,
  });

  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(
    ({ region }) => {
      return getSchoolResultsStatistics({
        exam_year: examYear?.uuid,
        page_size: 50,
        region,
      }).then((res) => {
        setSchoolStatistics(res);
        const data = {
          first_round_participated_region: 0,
          first_round_participated_center: 0,
          first_round_participated_total: 0,
          first_round_not_participated_center: 0,
          first_round_not_participated_region: 0,
          first_round_not_participated_total: 0,
          first_round_passed_region: 0,
          first_round_passed_region_region: 0,
          first_round_passed_region_center: 0,
          first_round_passed_center: 0,
          first_round_passed_total: 0,
          second_round_participated_region: 0,
          second_round_participated_center: 0,
          second_round_participated_total: 0,
          second_round_passed_region: 0,
          second_round_passed_center: 0,
          second_round_passed_total: 0,
        };

        res.forEach((school) => {
          Object.keys(data).forEach((key) => {
            data[key] += school[key];
          });
        });

        setNumbersByAllSchools(data);
      });
    },
    [examYear?.uuid],
  );

  useEffect(() => {
    if (examYear?.uuid) {
      setLoading(true);
      getData({})?.finally(() => setLoading());

      getRegions(examYear?.uuid).then((res) => {
        setRegions(res.results);
      });
    }

    return () => {};
  }, [examYear, getData]);

  const columns = React.useMemo(
    () =>
      // examYear?.year === 2023
      //   ? TWOROUNDEXAM
      //   : examYear?.year === 2024
      //   ? ONEROUNDEXAM
      //   : ONEROUNDEXAM,
      TWOROUNDEXAM.filter(
        (col) => !col.allow || col.allow?.includes(examYear?.year),
      ),
    [examYear],
  );

  return !loading ? (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Участвовали в первом туре(Регион):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_participated_region}
          </span>
        </div>
        <div>
          Участвовали в первом туре(Центр):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_participated_center}
          </span>
        </div>
        <div>
          Участвовали в первом туре(Всего):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_participated_total}
          </span>
        </div>
      </div>
      {columns.find(
        (e) => e.accessor === 'first_round_not_participated_total',
      ) && (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Не участвовали в первом туре(Регион):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.first_round_not_participated_region}
            </span>
          </div>
          <div>
            Не участвовали в первом туре(Центр):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.first_round_not_participated_center}
            </span>
          </div>
          <div>
            Не участвовали в первом туре(Всего):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.first_round_not_participated_total}
            </span>
          </div>
        </div>
      )}
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Прошли первый тур(Регион):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_passed_region}
          </span>
        </div>
        <div>
          Прошли первый тур(Центр):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_passed_center}
          </span>
        </div>
        <div>
          Прошли первый тур(Всего):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_passed_total}
          </span>
        </div>
      </div>
      {columns.find(
        (e) => e.accessor === 'first_round_passed_region_region',
      ) && (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Прошли первый тур(Регион (exam: Регион)):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.first_round_passed_region_region}
            </span>
          </div>
          <div>
            Прошли первый тур(Центр (exam: Центр)):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.first_round_passed_region_center}
            </span>
          </div>
        </div>
      )}
      {columns.find(
        (e) => e.accessor === 'second_round_participated_total',
      ) && (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Участвовали в втором туре(Регион):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_participated_region}
            </span>
          </div>
          <div>
            Участвовали в втором туре(Центр):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_participated_center}
            </span>
          </div>
          <div>
            Участвовали в втором туре(Всего):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_participated_total}
            </span>
          </div>
        </div>
      )}
      {columns.find((e) => e.accessor === 'second_round_passed_total') && (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Прошли второй тур(Регион):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_passed_region}
            </span>
          </div>
          <div>
            Прошли второй тур(Центр):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_passed_center}
            </span>
          </div>
          <div>
            Прошли второй тур(Всего):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_passed_total}
            </span>
          </div>
        </div>
      )}
      <div className='flex flex-row justify-between'>
        <Select
          className={`invisible h-6 w-48 ${
            user?.roles?.some((role) => role?.code.includes('RC')) && 'hidden'
          }`}
          placeholder={'Регион'}
          options={[
            { label: 'Все регионы', value: '' },
            ...regions.map((region, i) => ({
              label: region?.name,
              value: region?.uuid,
            })),
          ]}
          value={region}
          onChange={(e) => {
            // setSelectedDistrict(e);
            setRegion(e.value);
            getData({ region: e.value });
          }}
        />
        <ExportExcelExamStats params={{ region, exam_year: examYear?.uuid }} />
      </div>
      <Table columns={columns} data={schoolStatistics} />
    </div>
  ) : (
    <SimpleLoader className='h-16' />
  );
};

export default SchoolExamStatistics;
