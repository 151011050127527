import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SecretCompVersion({ result, language, math }) {
  const [t] = useTranslation();

  return (
    <>
      <table className='border-collapse border'>
        <tbody>
          <tr>
            <tr>
              <td className='border p-2'>{t('results.full_name')}</td>
              <td className='border p-2'>
                {result?.application.first_name} {result?.application.last_name}
              </td>
            </tr>
          </tr>
          <tr>
            <td className='border p-2'>{t('results.school')}</td>
            <td className='border p-2'>{result?.application.school.name}</td>
          </tr>
          <tr>
            <td className='border p-2'>{t('results.result')}</td>
            <td className='border p-2 font-semibold'>
              {result?.status === 0 && t('results.not_passed')}
              {result?.status === 1 && t('results.reserve')}
              {result?.status === 2 && (
                <span className='font-semibold text-green-600'>
                  {t('results.passed')}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className='border p-2'>{t('results.variant')}</td>
            <td className='border p-2'>{result?.exam_variant}</td>
          </tr>
          <tr>
            <td className='border p-2'>{t('results.points')}</td>
            <td className='border p-2'>{result?.result}</td>
          </tr>
        </tbody>
      </table>
      <table className='border-collapse border text-center'>
        <thead>
          <tr>
            <td className='border p-2'>{t('results.math_correct')}</td>
            <td className='border p-2'>{t('results.math_wrong')}</td>
            <th className='border p-2'>{t('results.math_points')}</th>
            <td className='border p-2'>{t('results.language_correct')}</td>
            <td className='border p-2'>{t('results.language_wrong')}</td>
            <th className='border p-2'>{t('results.language_points')}</th>
            <td className='border p-2'>{t('results.correct')}</td>
            <td className='border p-2'>{t('results.wrong')}</td>
            <th className='border p-2'>{t('results.points')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='border p-2'>{result?.math_correct}</td>
            <td className='border p-2'>{result?.math_wrong}</td>
            <th className='border p-2'>{result?.math_points}</th>
            <td className='border p-2'>{result?.language_correct}</td>
            <td className='border p-2'>{result?.language_wrong}</td>
            <th className='border p-2'>{result?.language_points}</th>
            <td className='border p-2'>
              {result && result.math_correct + result.language_correct}
            </td>
            <td className='border p-2'>
              {result && result.math_wrong + result.language_wrong}
            </td>
            <th className='border p-2'>{result?.result}</th>
          </tr>
        </tbody>
      </table>
      <table className='w-full border-collapse'>
        <tbody className='text-center uppercase'>
          {math.map((underMath, i) => (
            <React.Fragment key={i}>
              <tr>
                <th className='border p-2 normal-case'>{t('results.math')}</th>
                {underMath.map((q, b) => (
                  <th key={q.uuid} className='border p-2'>
                    {30 * i + b + 1}
                  </th>
                ))}
              </tr>
              <tr>
                <td className='border p-2 normal-case'>
                  {t('results.your_answer')}
                </td>
                {underMath.map((q) => (
                  <td
                    key={q.uuid}
                    className={`border p-2 text-white ${
                      q.answer === q.question.correct_answer
                        ? 'bg-green-500'
                        : q.answer
                        ? 'bg-red-500'
                        : 'bg-yellow-500'
                    }`}
                  >
                    {q.answer}
                  </td>
                ))}
              </tr>
              <tr>
                <td className='border p-2 normal-case'>
                  {t('results.correct_answer')}
                </td>
                {underMath.map((q) => (
                  <td key={q.uuid} className='border p-2'>
                    {q.question.correct_answer}
                  </td>
                ))}
              </tr>
              <tr className='h-8' />
            </React.Fragment>
          ))}
          {language.map((underLang, i) => (
            <React.Fragment key={i}>
              <tr>
                <th className='border p-2 normal-case'>{t('results.lang')}</th>
                {underLang.map((q, b) => (
                  <th key={q.uuid} className='border p-2'>
                    {30 * i + b + 1}
                  </th>
                ))}
              </tr>
              <tr>
                <td className='border p-2 normal-case'>
                  {t('results.your_answer')}
                </td>
                {underLang.map((q, b) => (
                  <td
                    key={q.uuid}
                    className={`border p-2 text-white ${
                      q.answer === q.question.correct_answer
                        ? 'bg-green-500'
                        : q.answer
                        ? 'bg-red-500'
                        : 'bg-yellow-500'
                    }`}
                  >
                    {q.answer}
                  </td>
                ))}
              </tr>
              <tr>
                <td className='border p-2 normal-case'>
                  {t('results.correct_answer')}
                </td>
                {underLang.map((q, b) => (
                  <td key={q.uuid} className='border p-2'>
                    {q.question.correct_answer}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
}
