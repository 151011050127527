import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import Input from '../../components/forms/Input/Input';
import Select from '../../components/forms/Select/Select';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { getDistricts } from '../../services/catalog/catalog';

export default function ContactInfo({
  applicant,
  setBody,
  nextPage,
  setContactsPartCompleted,
  setSelectedBilSchool,
  setSelectedDistrict,
  setSelectedExamCenter,
  bilSchools,
  schoolsError,
}) {
  const [t] = useTranslation();

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  const [districts, setDistricts] = useState([]);
  const [examCenters, setExamCenters] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isShuchinsk, setIsShuchinsk] = useState(false);
  // const [isTurkistan, setIsTurkistan] = useState(false);

  const onSubmit = async (data) => {
    // console.log('contactInfo:', data);
    setBody((body) => ({
      ...body,
      address: data.address,
      school: data.bilSchool,
      parent_first_name: data.parentFirstName,
      parent_last_name: data.parentLastName,
      district: data.district,
      exam_center: data.examCenter,
      parent_phone_number: data.whatsapp,
      parent_phone_number_2: data.whatsappTwo,
      email: data.email,
      language: data.language,
      current_school_name: data.school,
    }));

    setContactsPartCompleted(true);
    nextPage(2);
  };

  useEffect(() => {
    if (applicant?.current_school_name) {
      setValue('address', applicant?.address);
      setValue('bilSchool', applicant?.school);
      setValue('parentFirstName', applicant?.parent_first_name);
      setValue('parentLastName', applicant?.parent_last_name);
      setValue('whatsapp', applicant?.parent_phone_number);
      setValue('whatsappCopy', applicant?.parent_phone_number);
      setValue('whatsappTwo', applicant?.parent_phone_number_2);
      setValue('whatsappTwoCopy', applicant?.parent_phone_number_2);
      setValue('language', applicant?.language);
      setValue('email', applicant?.email);
      setValue('school', applicant?.current_school_name);
    }

    if (applicant?.district && applicant?.exam_center) {
      getDistricts({ school: applicant?.school, page_size: 50 })
        .then((res) => {
          const options = res.results
            ?.filter((d) => d?.is_geographical)
            .map((item) => ({
              label: item.name,
              value: item.uuid,
            }));
          setDistricts(options);

          const centerOptions = res.results
            ?.filter((d) => d?.is_exam_center && !d?.is_full)
            .map((item) => ({
              label: item.name,
              value: item.uuid,
            }));
          setExamCenters(centerOptions);
        })
        .finally(() => {
          setValue('district', applicant?.district);
          setValue('examCenter', applicant?.exam_center);
        });
    }

    return () => {};
  }, [applicant, setValue]);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <CustomDialog
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        closeModal={() => {}}
        headerText={
          <div className='flex items-center'>
            <span className='mr-2 inline-block text-yellow-500'>
              <ExclamationCircleIcon className='w-6' />
            </span>
            {t('signup.warning')}
          </div>
        }
      >
        <div className='flex flex-col gap-4'>
          {isShuchinsk && <p>{t('signup.warning_shuchinsk')}</p>}
          {/* {isTurkistan && <p>{t('signup.warning_turkistan')}</p>} */}
          <Button
            type='button'
            text={t('signup.understand')}
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
      </CustomDialog>
      <form
        className='flex h-full flex-col justify-between'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='grid grid-cols-12 gap-4'>
          {/* 1. BIL school */}
          <div className='col-span-12 sm:col-span-6'>
            <Controller
              control={control}
              name='bilSchool'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={t('signup.which_bil_school')}
                  required
                  errorMsg={
                    schoolsError
                      ? schoolsError?.code
                      : errors?.bilSchool?.type
                      ? t('signup.school_error')
                      : ''
                  }
                  value={field?.value}
                  className='w-full'
                  options={bilSchools}
                  // placeholder={t('signup.select_bil_school')}
                  onChange={(e) => {
                    if (e.school_id === '051') {
                      setModalOpen(true);
                      setIsShuchinsk(true);
                    } else {
                      setIsShuchinsk(false);
                    }
                    // if (e.school_id === '057') {
                    //   setModalOpen(true);
                    //   setIsTurkistan(true);
                    // } else {
                    //   setIsTurkistan(false);
                    // }
                    setSelectedBilSchool(e);
                    field.onChange(e.value);
                    setValue('district', null);
                    setValue('examCenter', null);
                    getDistricts({
                      school: e.value,
                      page_size: 50,
                      // is_geographical: true,
                    }).then((res) => {
                      const options = res.results
                        ?.filter((d) => d?.is_geographical)
                        .map((item) => ({
                          label: item.name,
                          value: item.uuid,
                        }));

                      const centerOptions = res.results
                        ?.filter((d) => d?.is_exam_center && !d?.is_full)
                        .map((item) => ({
                          label: item.name,
                          value: item.uuid,
                        }));
                      setDistricts(options);
                      setExamCenters(centerOptions);
                    });
                  }}
                />
              )}
            />
            {isShuchinsk && (
              <span className='text-red-600'>
                {t('signup.select_another_school')}
              </span>
            )}
          </div>
          {/* 2. Exam center */}
          <div className='col-span-12 sm:col-span-6'>
            <Controller
              control={control}
              name='examCenter'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={t('signup.exam_center')}
                  required
                  errorMsg={
                    errors?.district?.type ? t('signup.select_exam_center') : ''
                  }
                  className='w-full'
                  value={field.value}
                  options={examCenters}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => {
                    setSelectedExamCenter(e);
                    field.onChange(e.value);
                  }}
                />
              )}
            />
          </div>
          <div className='col-span-12 border border-black-landing'></div>
          {/* 3. Address */}
          <div className='col-span-12 sm:col-span-6'>
            <Input
              type='text'
              className='w-full'
              required
              errorMsg={errors?.address?.type ? t('signup.enter_address') : ''}
              label={t('signup.address')}
              {...register('address', {
                required: true,
              })}
            />
          </div>
          {/* 4. District */}
          <div className='col-span-12 sm:col-span-6'>
            <Controller
              control={control}
              name='district'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={t('signup.region')}
                  required
                  errorMsg={
                    errors?.district?.type ? t('signup.select_region') : ''
                  }
                  className='w-full'
                  value={field.value}
                  options={districts}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => {
                    setSelectedDistrict(e);
                    field.onChange(e.value);
                  }}
                />
              )}
            />
          </div>
          {/* 5. Parent name */}
          <div className='col-span-12 sm:col-span-4'>
            <Input
              type='text'
              className='w-full'
              label={t('signup.parent_name')}
              required
              errorMsg={
                errors?.parentFirstName?.type
                  ? t('signup.enter_parent_name')
                  : ''
              }
              {...register('parentFirstName', {
                required: true,
              })}
            />
          </div>
          {/* 6. Parent surname */}
          <div className='col-span-12 sm:col-span-4'>
            <Input
              type='text'
              className='w-full'
              label={t('signup.parent_surname')}
              required
              errorMsg={
                errors?.parentLastName?.type
                  ? t('signup.enter_parent_surname')
                  : ''
              }
              {...register('parentLastName', {
                required: true,
              })}
            />
          </div>
          {/* 7. Email */}
          <div className='col-span-12 sm:col-span-4'>
            <Input
              type='email'
              className='w-full'
              label={t('signup.parent_email')}
              required
              errorMsg={errors?.email?.type ? t('signup.enter_email') : ''}
              {...register('email', {
                required: true,
              })}
            />
          </div>
          {/* 8. First Whatsapp */}
          <div className='col-span-12 md:col-span-6'>
            <div className='flex flex-col gap-2'>
              <div className='w-full'>
                <label
                  htmlFor='whatsapp'
                  className='block text-sm font-medium text-gray-700'
                >
                  {t('signup.parent_phone')}
                  <span className='text-error'>*</span>
                </label>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                    +7
                  </span>
                  <input
                    type='text'
                    name='whatsapp'
                    id='whatsapp'
                    className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                    // placeholder='www.example.com'
                    // errorMsg={
                    //   errors?.whatsapp?.type ? t('signup.parent_phone_error') : ''
                    // }
                    {...register('whatsapp', {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: {
                        value: /^7[0-9]{9}?$/,
                      },
                    })}
                  />
                </div>
                {errors?.whatsapp?.type && (
                  <span className='mt-1 text-xs font-semibold text-error'>
                    {t('signup.parent_phone_error')}
                  </span>
                )}
              </div>

              <div className='w-full'>
                <label
                  htmlFor='whatsappCopy'
                  className='block text-sm font-medium text-gray-700'
                >
                  {t('signup.confirm_phone')}
                  <span className='text-error'>*</span>
                </label>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                    +7
                  </span>
                  <input
                    type='text'
                    name='whatsappCopy'
                    id='whatsappCopy'
                    className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                    // placeholder='www.example.com'
                    {...register('whatsappCopy', {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      validate: (val) => {
                        if (watch('whatsapp') !== val) {
                          return t('signup.confirm_error');
                        }
                      },
                    })}
                    // errorMsg={
                    //   errors?.whatsappCopy?.type === 'required'
                    //     ? t('signup.parent_phone_error')
                    //     : errors?.whatsappCopy?.message
                    // }
                  />
                </div>
                {errors?.whatsappCopy?.type && (
                  <span className='mt-1 text-xs font-semibold text-error'>
                    {t('signup.parent_phone_error')}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* 9. Second Whatsapp */}
          <div className='col-span-12 md:col-span-6 md:border-l md:pl-4'>
            <div className='flex flex-col gap-2'>
              <div className='w-full'>
                <label
                  htmlFor='whatsappTwo'
                  className='block text-sm font-medium text-gray-700'
                >
                  {t('signup.parent_phone_two')}
                  <span className='text-error'>*</span>
                </label>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                    +7
                  </span>
                  <input
                    type='text'
                    name='whatsappTwo'
                    id='whatsappTwo'
                    className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                    // placeholder='www.example.com'
                    // errorMsg={
                    //   errors?.whatsapp?.type ? t('signup.parent_phone_error') : ''
                    // }
                    {...register('whatsappTwo', {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: {
                        value: /^7[0-9]{9}?$/,
                      },
                      validate: (val) => {
                        if (watch('whatsapp') === val) {
                          return t('signup.confirm_error');
                        }
                      },
                    })}
                  />
                </div>
                {errors?.whatsapp?.type && (
                  <span className='mt-1 text-xs font-semibold text-error'>
                    {t('signup.parent_phone_error')}
                  </span>
                )}
              </div>

              <div className='w-full'>
                <label
                  htmlFor='whatsappTwoCopy'
                  className='block text-sm font-medium text-gray-700'
                >
                  {t('signup.confirm_phone')}
                  <span className='text-error'>*</span>
                </label>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                    +7
                  </span>
                  <input
                    type='text'
                    name='whatsappTwoCopy'
                    id='whatsappTwoCopy'
                    className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                    // placeholder='www.example.com'
                    {...register('whatsappTwoCopy', {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      validate: (val) => {
                        if (watch('whatsappTwo') !== val) {
                          return t('signup.confirm_error');
                        }
                      },
                    })}
                    // errorMsg={
                    //   errors?.whatsappCopy?.type === 'required'
                    //     ? t('signup.parent_phone_error')
                    //     : errors?.whatsappCopy?.message
                    // }
                  />
                </div>
                {errors?.whatsappCopy?.type && (
                  <span className='mt-1 text-xs font-semibold text-error'>
                    {t('signup.parent_phone_error')}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* 10. Language */}
          <div className='col-span-12 sm:col-span-6'>
            <Controller
              control={control}
              name='language'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={t('signup.study_lang')}
                  required
                  errorMsg={
                    errors?.language?.type ? t('signup.select_study_lang') : ''
                  }
                  className='w-full'
                  value={field.value}
                  options={[
                    {
                      label: 'Қазақша',
                      value: 'K',
                    },
                    {
                      label: 'Русский',
                      value: 'R',
                    },
                  ]}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </div>
          {/* 11. Current school */}
          <div className='col-span-12 sm:col-span-6'>
            <Input
              type='text'
              className='w-full'
              label={t('signup.enter_school_name')}
              required
              errorMsg={
                errors?.school?.type ? t('signup.enter_school_name') : ''
              }
              {...register('school', {
                required: true,
              })}
            />
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex flex-row justify-end'>
            {/* <Button type='button' text='Back' color='secondary' /> */}
            <Button
              disabled={isShuchinsk}
              type='submit'
              text={t('buttons.save')}
            />
          </div>
        </div>
      </form>
    </>
  );
}
