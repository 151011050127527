import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SecretMobVersion({ result, language, math }) {
  const [t] = useTranslation();

  return (
    <>
      <table className='min-w-[50%] border-collapse break-normal border text-sm sm:text-base'>
        <tbody>
          <tr>
            <td className='border p-1'>{t('results.full_name')}</td>
            <td className='border p-1'>
              {result?.application.first_name} {result?.application.last_name}
            </td>
          </tr>
          <tr>
            <td className='border p-1'>{t('results.school')}</td>
            <td className='border p-1'>{result?.application.school.name}</td>
          </tr>
          <tr>
            <td className='border p-1'>{t('results.result')}</td>
            <td className='border p-1 font-semibold'>
              {result?.status === 0 && t('results.not_passed')}
              {result?.status === 1 && t('results.reserve')}
              {result?.status === 2 && (
                <span className='font-semibold text-green-600'>
                  {t('results.passed')}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className='border p-1'>{t('results.variant')}</td>
            <td className='border p-1'>{result?.exam_variant}</td>
          </tr>
          <tr>
            <td className='border p-1'>{t('results.points')}</td>
            <td className='border p-1'>{result?.result}</td>
          </tr>
        </tbody>
      </table>
      <table className='w-fit border-collapse break-normal border text-center'>
        <tbody>
          <tr>
            <td className='border p-1'>{t('results.math_correct')}</td>
            <td className='border p-1'>{t('results.math_wrong')}</td>
            <th className='border p-1'>{t('results.math_points')}</th>
          </tr>
          <tr>
            <td className='border p-1'>{result?.math_correct}</td>
            <td className='border p-1'>{result?.math_wrong}</td>
            <th className='border p-1'>{result?.math_points}</th>
          </tr>
          <tr>
            <td className='border p-1'>{t('results.language_correct')}</td>
            <td className='border p-1'>{t('results.language_wrong')}</td>
            <th className='border p-1'>{t('results.language_points')}</th>
          </tr>
          <tr>
            <td className='border p-1'>{result?.language_correct}</td>
            <td className='border p-1'>{result?.language_wrong}</td>
            <th className='border p-1'>{result?.language_points}</th>
          </tr>
          <tr>
            <td className='border p-1'>{t('results.correct')}</td>
            <td className='border p-1'>{t('results.wrong')}</td>
            <th className='border p-1'>{t('results.points')}</th>
          </tr>
          <tr>
            <td className='border p-1'>
              {result && result.math_correct + result.language_correct}
            </td>
            <td className='border p-1'>
              {result && result.math_wrong + result.language_wrong}
            </td>
            <th className='border p-1'>{result?.result}</th>
          </tr>
        </tbody>
      </table>
      <table className='w-fit border-collapse break-normal'>
        <tbody className='text-center'>
          <tr>
            <th className='border p-1'>{t('results.math')}</th>
            <td className='border p-1'>{t('results.your_answer')}</td>
            <td className='border p-1'>{t('results.correct_answer')}</td>
          </tr>
          {math.map((q, i) => (
            <tr key={q.uuid} className='uppercase'>
              <td className='border p-1'>{i + 1}</td>

              <td
                className={`border p-1 text-white ${
                  q.answer === q.question.correct_answer
                    ? 'bg-green-500'
                    : q.answer
                    ? 'bg-red-500'
                    : 'bg-yellow-500'
                }`}
              >
                {q.answer}
              </td>
              <td className='border p-1'>{q.question.correct_answer}</td>
            </tr>
          ))}
          <tr className='h-8' />
          <tr>
            <th className='border p-1'>{t('results.lang')}</th>
            <td className='border p-1'>{t('results.your_answer')}</td>
            <td className='border p-1'>{t('results.correct_answer')}</td>
          </tr>
          {language.map((q, i) => (
            <tr key={q.uuid} className='uppercase'>
              <td className='border p-1'>{i + 1}</td>

              <td
                className={`border p-1 text-white ${
                  q.answer === q.question.correct_answer
                    ? 'bg-green-500'
                    : q.answer
                    ? 'bg-red-500'
                    : 'bg-yellow-500'
                }`}
              >
                {q.answer}
              </td>
              <td className='border p-1'>{q.question.correct_answer}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
