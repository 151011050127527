import React, { useContext, useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import { getSchoolPassScoreStats } from '../../services/schoolStatistics/schoolStatistics';

const TWOROUNDEXAM = [
  {
    Header: 'ID',
    accessor: 'school_id',
  },
  {
    Header: 'Название',
    accessor: 'name',
  },
  {
    Header: 'Pass score 1 round',
    accessor: 'first_round_pass_scores.pass_score_kz',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Pass score 1 round asp',
    accessor: 'first_round_pass_scores.pass_score_asp_kz',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Pass score 2 round',
    accessor: 'second_round_pass_scores.pass_score_kz',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Pass score 2 round asp',
    accessor: 'second_round_pass_scores.pass_score_asp_kz',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
];

const ONEROUNDEXAM = [
  {
    Header: 'ID',
    accessor: 'school_id',
  },
  {
    Header: 'Название',
    accessor: 'name',
  },
  {
    Header: 'Pass score 1 round',
    accessor: 'first_round_pass_scores.pass_score_kz',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Pass score 1 round asp',
    accessor: 'first_round_pass_scores.pass_score_asp_kz',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
];

const SchoolPassScoreStatistics = () => {
  const [schoolStatistics, setSchoolStatistics] = useState([]);
  const { examYear } = useContext(ExamYearContext);

  useEffect(() => {
    getSchoolPassScoreStats({ page_size: 50, exam_year: examYear.uuid }).then(
      (res) => {
        setSchoolStatistics(res);
      },
    );

    return () => {};
  }, [examYear.uuid]);

  const columns = React.useMemo(
    () => (examYear?.year === 2023 ? TWOROUNDEXAM : ONEROUNDEXAM),
    [examYear],
  );

  return (
    <div className='flexflex-col gap-4'>
      <Table columns={columns} data={schoolStatistics} isSticky />
    </div>
  );
};

export default SchoolPassScoreStatistics;
