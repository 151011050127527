import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getResults } from '../../services/results/resultsService';
import NewResultForm from '../ResultsStudents/MyResults/NewResultForm/NewResultForm';
import SimpleLoader from '../../components/Loader/SimpleLoader';

export default function StudentAnswers() {
  const { uuid } = useParams();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getResults({ application: uuid })
      .then((res) => {
        if (res.results?.length) {
          setResult(res.results[0]);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, [uuid]);

  return (
    <div className='flex flex-col gap-2'>
      {loading ? (
        <SimpleLoader className='h-5' />
      ) : (
        result && result.exam_year >= 2024 && <NewResultForm result={result} />
      )}
    </div>
  );
}
