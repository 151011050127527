import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/solid';
import {
  deleteSocialDocument,
  getSocialDocuments,
  postSocialDocument,
} from '../../../services/socialDocs/socialDocs';
import { useTranslation } from 'react-i18next';
import Select from '../../../components/forms/Select/Select';
import { patchApplication } from '../../../services/application/application';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { showImageOrPdf } from '../../../utils/helpers/helpers';

export default function VulnerableStudentDocuments({
  applicant,
  vulnerableGroups,
  setApplicantInfo,
  setVulnerableDocsLength,
}) {
  const [i18n] = useTranslation();
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();

  const [fileList, setFileList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isAsp, setIsAsp] = useState(applicant?.social_doc_type ? true : false);
  const [selectedGroup, setSelectedGroup] = useState(
    applicant?.social_doc_type,
  );
  const [uploadDisabled, setUploadDisabled] = useState(false);

  useEffect(() => {
    if (applicant?.uuid) {
      getSocialDocuments({ application: applicant.uuid }).then((res) => {
        setUploadedDocuments(res.results);
        setVulnerableDocsLength(res.results.length);
      });
    }

    return () => {};
  }, [applicant, setVulnerableDocsLength]);

  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };

  const handleUploadClick = async () => {
    setLoading(true);
    if (!fileList || !selectedGroup.uuid) {
      return;
    }

    for (const file of files) {
      const data = new FormData();

      data.append('application', applicant?.uuid);
      data.append(`document`, file, file.name);

      try {
        await postSocialDocument(data).then((res) => {
          // console.log('res', res);
        });
      } catch (error) {
        addSnackbar(t('Ошибка'), 'error');
      }
    }

    try {
      await getSocialDocuments({ application: applicant?.uuid })
        .then((res) => {
          setUploadedDocuments(res.results);
          setVulnerableDocsLength(res.results.length);
        })
        .finally(() => {
          setLoading(false);
          setFileList(null);
          setUploadDisabled(false);
        });
    } catch (error) {
      addSnackbar(t('Ошибка'), 'error');
    }
  };

  const files = fileList ? [...fileList] : [];

  return (
    <div className=''>
      <div className='p-4'>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          Документы, подтверждающие принадлежность к социально уязвимым группам
        </h3>
      </div>
      <div className='flex cursor-pointer items-center border-y p-4'>
        <input
          id='checkRU'
          name='checkRU'
          type='checkbox'
          checked={isAsp}
          disabled
          value={isAsp}
          onChange={(e) => {
            setIsAsp((v) => !v);

            if (e.target.checked === false) {
              setUploadDisabled(true);
              const body = {
                social_doc_type: '',
                // asp_status: getAspStatus(),
              };
              patchApplication(applicant?.uuid, body)
                .then((res) => {
                  setApplicantInfo(res);
                  setSelectedGroup(null);
                  addSnackbar(t('Категория изменена'), 'success');
                })
                .finally(() => {
                  setUploadDisabled(false);
                });
            }
          }}
          className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
        />
        <label htmlFor='checkRU' className={'ml-2 block'}>
          Относится ли ученик к социально уязвимым категориям населения
        </label>
      </div>
      <div
        className={`${
          isAsp ? '' : 'pointer-events-none opacity-50'
        } flex flex-col gap-4`}
      >
        <div className='mx-4 mt-2 flex flex-row items-center justify-between'>
          <h3 className='w-2/3'>
            <span className='text-lg font-semibold text-blue-600'>
              Категория:
            </span>
            {/* {applicant?.social_doc_type?.name} */}
            <Select
              loading={uploadDisabled}
              isSearchable={false}
              className=''
              value={selectedGroup?.uuid}
              options={[
                // { label: t('signup.not_in_category'), value: null },
                ...vulnerableGroups.map((group, i) => ({
                  label:
                    `${i + 1}. ` +
                    (i18n.language === 'kk' ? group?.name : group?.name_ru),
                  value: group?.uuid,
                  docs_list: { kz: group?.files, ru: group?.files_ru },
                  data: group,
                })),
              ]}
              // placeholder={t('announcement.select_positions')}
              // onChange={(e) => field.onChange(e.value)}
              onChange={(e) => {
                setSelectedGroup(e.data);
                setUploadDisabled(true);
                const body = {
                  social_doc_type: isAsp ? e.data?.uuid : '',
                  // asp_status: getAspStatus(),
                };
                patchApplication(applicant?.uuid, body)
                  .then((res) => {
                    setApplicantInfo(res);
                    addSnackbar(t('Категория изменена'), 'success');
                  })
                  .finally(() => {
                    setUploadDisabled(false);
                  });
              }}
            />
          </h3>
          {/* <Button text='Добавить документ' Icon={PlusIcon} /> */}
        </div>
        <div className='mx-4'>
          <p className='font-semibold text-blue-600'>
            Список подтверждающих документов:
          </p>
          <div>
            <ul className='rounded-md border bg-white p-2'>
              {selectedGroup?.files.map((file, i) => (
                <li className='m-1' key={i}>
                  {`${i + 1}. ` + file}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='mx-4'>
          <p className='mb-2 font-semibold text-blue-600'>
            Загруженные документы:
          </p>
          <div className='grid min-h-[4rem] grid-cols-3 gap-2'>
            {uploadedDocuments?.map((doc) =>
              doc?.document ? (
                <div
                  key={doc?.uuid}
                  className='flex flex-col justify-between rounded-md border p-2'
                >
                  {/* <img
                    src={doc?.document}
                    alt='doc'
                    className='h-32 object-cover'
                  /> */}
                  {showImageOrPdf(doc?.document, 32)}
                  <Button
                    className='mt-2 w-16'
                    color='error'
                    Icon={TrashIcon}
                    onClick={() => {
                      setUploadDisabled(true);
                      deleteSocialDocument(doc?.uuid).then(() => {
                        getSocialDocuments({
                          application: applicant?.uuid,
                        })
                          .then((res) => {
                            setUploadedDocuments(res.results);
                            setVulnerableDocsLength(res.results.length);
                          })
                          .finally(() => {
                            setUploadDisabled(false);
                          });
                      });
                    }}
                  />
                </div>
              ) : (
                <div></div>
              ),
            )}
          </div>
        </div>
        <div className='m-4 rounded-md border p-2 '>
          <p className='font-semibold text-blue-600'>Выберите документы:</p>
          <div>
            <input type='file' onChange={handleFileChange} multiple />

            <ul className='mt-2 border-y bg-white p-2'>
              {files?.length ? (
                files.map((file, i) => (
                  <li className='m-1' key={i}>
                    {file.name} - {file.type}
                  </li>
                ))
              ) : (
                <div className='text-center italic text-gray-600'>
                  Загрузите файлы
                </div>
              )}
            </ul>
          </div>
          <div className='grid grid-cols-3'>
            {/* <div className=' box-border h-64 w-36 border bg-slate-600'></div>
          <div className=' box-border h-64 w-36 border bg-slate-600'></div>
          <div className=' box-border h-64 w-36 border bg-slate-600'></div>
        <div className=' box-border h-64 w-36 border bg-slate-600'></div> */}
          </div>
          <div className='flex flex-row justify-end px-4'>
            <Button
              className='my-2'
              loading={loading}
              disabled={uploadDisabled || files?.length === 0}
              Icon={ArrowUpTrayIcon}
              text='Загрузить документы'
              onClick={() => handleUploadClick()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
