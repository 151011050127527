import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';

export default function Application({
  applicant,
  nextPage,
  setStatementPartCompleted,
  selectedBilSchool,
  setVulnerableGroupPartCompleted,
}) {
  const { t, i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className='flex h-full flex-col justify-between'>
      <div className='flex justify-center'>
        {i18n?.language === 'kk' ? (
          <div className='border bg-white py-4 px-8 sm:h-[32rem] sm:w-[48rem]'>
            <h4 className='text-center text-xl'>ӨТІНІШ</h4>
            <br />
            <p>
              Менің{' '}
              <b className='underline'>
                {applicant?.first_name && applicant?.last_name ? (
                  applicant.first_name + ' ' + applicant.last_name
                ) : (
                  <span>__________________________</span>
                )}
              </b>
              , есімді баламды{' '}
              <b className='underline'>
                {applicant?.school ? (
                  selectedBilSchool?.label
                ) : (
                  <span>______________________</span>
                )}
              </b>
              {` мекемесінің 7 сыныбында оқу үшін конкурстық іріктеуге жіберуіңізді сұраймын.`}
            </p>
            <br />
            <p>
              Үміткердің конкурстық іріктеудің барлық кезеңдеріне қатысуын өз
              жауапкершілігіме аламын және үміткердің конкурстың барлық
              кезеңдерін басқа біреудің қатысуынсыз өз бетінше өтуін қамтамасыз
              етуге міндеттенемін.
            </p>
            <br />
            <p>
              Ата-ананың (заңды өкілдің) аты-жөні:{' '}
              <b className='underline'>
                {applicant?.parent_first_name && applicant?.parent_last_name ? (
                  applicant.parent_first_name + ' ' + applicant.parent_last_name
                ) : (
                  <span>__________________________</span>
                )}
              </b>
              .
            </p>
            <br />
            <div className='flex items-center'>
              <input
                id='checkKZ'
                name='checkKZ'
                type='checkbox'
                checked={isChecked}
                value={isChecked}
                onChange={() => setIsChecked((v) => !v)}
                className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
              />
              <label htmlFor='checkKZ' className={'ml-2 block text-red-600'}>
                Торды белгілей отырып, осы өтінішке қол қойғанымды растаймын.
              </label>
            </div>
            {/* <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          isInvalid={!checkStatement}
          checked={checkStatement}
          type="checkbox"
          onChange={() => setCheckStatement(!checkStatement)}
          label="* Отмечая эту клетку, я подтверждаю, что подписываю данное заявление."
        />
      </Form.Group> */}
            <br />
            <br />
            <p className='text-right'>
              {'Күні: ' + moment(Date.now()).format('DD.MM.yyyy')}
            </p>
          </div>
        ) : (
          <div className='border bg-white py-4 px-8 sm:h-[32rem] sm:w-[48rem]'>
            <h4 className='text-center text-xl'>ЗАЯВЛЕНИЕ</h4>
            <br />
            <p>
              Прошу допустить моего ребенка,
              <b className='underline'>
                {applicant?.first_name && applicant?.last_name ? (
                  applicant.first_name + ' ' + applicant.last_name
                ) : (
                  <span>__________________________</span>
                )}
              </b>
              , к конкурсному отбору для обучения в 7 класс учреждения{' '}
              <b className='underline'>
                {applicant?.school ? (
                  selectedBilSchool?.label
                ) : (
                  <span>______________________</span>
                )}
              </b>
              .
            </p>
            <br />
            <p>
              Я несу персональную ответственность за явку претендента на все
              этапы конкурсного отбора и обязуюсь обеспечить прохождение
              претендентом все этапы конкурса самостоятельно, без участия
              посредников.
            </p>
            <br />
            <p>
              ФИО родителя (законного представителя):{' '}
              <b className='underline'>
                {applicant?.parent_first_name && applicant?.parent_last_name ? (
                  applicant.parent_first_name + ' ' + applicant.parent_last_name
                ) : (
                  <span>__________________________</span>
                )}
              </b>
              .
            </p>
            <br />
            <div className='flex items-center'>
              <input
                id='checkRU'
                name='checkRU'
                type='checkbox'
                checked={isChecked}
                value={isChecked}
                onChange={() => setIsChecked((v) => !v)}
                className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
              />
              <label htmlFor='checkRU' className={'ml-2 block text-red-600'}>
                Отмечая эту клетку, я подтверждаю, что подписываю данное
                заявление.
              </label>
            </div>
            <br />
            <br />
            <p className='text-right'>
              {'Дата: ' + moment(Date.now()).format('DD.MM.yyyy')}
            </p>
          </div>
        )}
      </div>
      <div className=''>
        <div className='mt-6 flex flex-row justify-end'>
          {/* <Button type='button' text='Back' color='secondary' /> */}
          <Button
            type='submit'
            text={t('buttons.save')}
            onClick={() => {
              if (isChecked) {
                setStatementPartCompleted(true);
                console.log('first', selectedBilSchool.has_social);
                if (selectedBilSchool?.has_social) {
                  nextPage(4);
                }
                if (!selectedBilSchool?.has_social) {
                  setVulnerableGroupPartCompleted(true);
                  nextPage(5);
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
