import { UserIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../../../components/forms/Buttons/Button';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import Input from '../../../components/forms/Input/Input';
import Select from '../../../components/forms/Select/Select';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import i18n from '../../../lang/i18n';
import { postApplication } from '../../../services/application/application';
import { getDistricts } from '../../../services/catalog/catalog';
import { getSocialDocumentsTypes } from '../../../services/socialDocs/socialDocsTypes';
import { UserContext } from '../../../utils/contexts/UserContext';
import useSnackbar from '../../../utils/hooks/useSnackbar';

const schema = yup.object({
  iin: yup
    .string()
    .length(12, i18n.t('signup.iin_error'))
    .matches(/^\d+$/g, i18n.t('signup.iin_error'))
    .required(i18n.t('signup.iin_error')),
  firstName: yup
    .string()
    .matches(
      /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯәӘіІңҢғҒүҮұҰқҚөӨһҺ]+$/g,
      i18n.t('signup.in_cyrillic'),
    )
    .required(i18n.t('signup.enter_name')),
  lastName: yup
    .string()
    .matches(
      /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯәӘіІңҢғҒүҮұҰқҚөӨһҺ]+$/g,
      i18n.t('signup.in_cyrillic'),
    )
    .required(i18n.t('signup.enter_name')),
  birthDate: yup.date().required(i18n.t('signup.enter_birth_date')),
  gender: yup.string().required(i18n.t('signup.select_gender')),
  // bilSchool: yup.string().required(i18n.t('signup.school_error')),
  address: yup.string().required(i18n.t('signup.enter_address')),
  district: yup.string().required(i18n.t('signup.select_region')),
  parentFirstName: yup.string().required(i18n.t('signup.enter_parent_name')),
  parentLastName: yup.string().required(i18n.t('signup.enter_parent_surname')),
  email: yup.string().email().required(i18n.t('signup.enter_email')),
  whatsapp: yup
    .string()
    .length(10, 'Номер должен состоять из 10 цифр')
    .matches(/^7[0-9]{9}?$/, 'Введите в правильном формате')
    .required(i18n.t('signup.parent_phone_error')),
  whatsappCopy: yup
    .string()
    .oneOf([yup.ref('whatsapp'), null], i18n.t('signup.confirm_error')),
  language: yup.string().required(i18n.t('signup.select_study_lang')),
  school: yup.string().required(i18n.t('signup.enter_school_name')),
  socialDocType: yup.string().required('Поле не может быть пустым'),
});

export default function CreateApplicant() {
  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { addSnackbar } = useSnackbar();

  // const [bilSchools, setBilSchools] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const fileRef = useRef(null);
  const [doc, setDoc] = useState(null);
  const [vulnerableGroups, setVulnerableGroups] = useState([]);

  useEffect(() => {
    if (user?.school?.uuid) {
      getDistricts({ school: user?.school?.uuid }).then((res) => {
        const options = res.results.map((item) => ({
          label: item.name,
          value: item.uuid,
        }));
        setDistricts(options);
      });

      getSocialDocumentsTypes().then((res) => {
        setVulnerableGroups(res.results);
      });
    }

    return () => {};
  }, [user, setValue]);

  const onSubmit = async (data) => {
    if (avatar && doc) {
      setLoading(true);
      const temp = {
        first_name: data.firstName,
        last_name: data.lastName,
        gender: data.gender,
        iin: data.iin,
        birth_date: format(data.birthDate, 'yyyy-MM-dd'),
        school: user?.school?.uuid,
        district: data.district,
        address: data.address,
        parent_first_name: data.parentFirstName,
        parent_last_name: data.parentLastName,
        parent_phone_number: data.whatsapp,
        language: data.language,
        current_school_name: data.school,
        social_doc_type: data.socialDocType,
      };

      const formData = new FormData();

      for (var key in temp) {
        if (temp[key] && temp[key] !== 'not_in') {
          formData.append(key, temp[key]);
        }
      }

      formData.append('avatar_photo', avatar, 'avatar_photo');
      formData.append('document', doc, 'document');

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      await postApplication(formData)
        .then(() => {
          setLoading(false);
          addSnackbar(t('Успешно'), 'success');
        })
        .catch((err) => {
          // console.log('err', err);
          setLoading(false);
          addSnackbar(
            err?.response?.data?.iin[0] && t('signup.iin_duplicate'),
            'error',
          );
        })
        .finally(() => {
          setLoading(false);
          navigate(-1);
        });
    }
  };

  // if (['007', '020', '021', '022'].includes(user?.school?.school_id)) {
  if (false) {
    return (
      <div className='md:flex md:flex-row md:justify-center'>
        <div className='mb-40 flex max-w-7xl flex-col'>
          <div></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* main */}
            <div>
              <div className='md:grid md:grid-cols-4 md:gap-6'>
                <div className='md:col-span-1'>
                  <div className='px-4 sm:px-0'>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      {t('signup.main_info')}
                    </h3>
                  </div>
                </div>
                <div className='mt-5 md:col-span-3 md:mt-0'>
                  <div className='shadow sm:rounded-md'>
                    <div className='space-y-6 bg-white px-4 py-5 sm:p-6'>
                      <div className='grid grid-cols-6 gap-6'>
                        <div className='col-span-6 sm:col-span-3'>
                          <Input
                            type='text'
                            className='pr-1'
                            label={t('signup.iin')}
                            required
                            errorMsg={errors?.iin?.message}
                            {...register('iin')}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3'></div>

                        <div className='col-span-6 sm:col-span-3'>
                          <Input
                            type='text'
                            className='w-full'
                            label={t('signup.name')}
                            required
                            errorMsg={errors?.firstName?.message}
                            // errorMsg={errors?.firstName?.type ? t('signup.enter_name') : ''}
                            {...register('firstName')}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3'>
                          <Input
                            type='text'
                            className='w-full'
                            label={t('signup.surname')}
                            required
                            errorMsg={errors?.lastName?.message}
                            {...register('lastName')}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3'>
                          <Controller
                            control={control}
                            name='birthDate'
                            render={({ field }) => (
                              <DatePicker
                                className='w-full'
                                label={t('signup.birth_date')}
                                required
                                errorMsg={errors?.birthDate?.message}
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                placeholderText={t('buttons.select')}
                                dateFormat='dd / MM / yyyy'
                                maxDate={new Date()}
                              />
                            )}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3'>
                          <Controller
                            control={control}
                            name='gender'
                            render={({ field }) => (
                              <Select
                                label={t('signup.gender')}
                                required
                                errorMsg={errors?.gender?.message}
                                isSearchable={false}
                                className='w-full overflow-visible'
                                value={field.value}
                                options={[
                                  {
                                    label: t('signup.male'),
                                    value: 'M',
                                  },
                                  {
                                    label: t('signup.female'),
                                    value: 'F',
                                  },
                                ]}
                                // placeholder={t('announcement.select_positions')}
                                onChange={(e) => field.onChange(e.value)}
                                // onChange={onChange}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='hidden sm:block' aria-hidden='true'>
              <div className='py-5'>
                <div className='border-t border-gray-200' />
              </div>
            </div>
            {/* contact */}
            <div className='mt-10 sm:mt-0'>
              <div className='md:grid md:grid-cols-4 md:gap-6'>
                <div className='md:col-span-1'>
                  <div className='px-4 sm:px-0'>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      {t('signup.contact_info')}
                    </h3>
                  </div>
                </div>
                <div className='mt-5 md:col-span-3 md:mt-0'>
                  <div className='shadow sm:rounded-md'>
                    <div className='space-y-6 bg-white px-4 py-5 sm:p-6'>
                      <div className='grid grid-cols-6 gap-6'>
                        <div className='col-span-6'>
                          <Controller
                            control={control}
                            name='bilSchool'
                            render={({ field }) => (
                              <Select
                                isDisabled
                                label={t('signup.bil_school')}
                                required
                                errorMsg={errors?.bilSchool?.message}
                                value={user?.school?.uuid}
                                className='w-full'
                                options={[
                                  {
                                    label: user?.school?.name,
                                    value: user?.school?.uuid,
                                  },
                                ]}
                                // placeholder={t('announcement.select_positions')}
                              />
                            )}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3'>
                          <Input
                            type='text'
                            className='w-full'
                            required
                            errorMsg={errors?.address?.message}
                            label={t('signup.address')}
                            {...register('address')}
                          />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                          <Controller
                            control={control}
                            name='district'
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                label={t('signup.region')}
                                required
                                errorMsg={errors?.district?.message}
                                className='w-full'
                                value={field.value}
                                options={districts}
                                // placeholder={t('announcement.select_positions')}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            type='text'
                            className='w-full'
                            label={t('signup.parent_name')}
                            required
                            errorMsg={errors?.parentFirstName?.message}
                            {...register('parentFirstName')}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            type='text'
                            className='w-full'
                            label={t('signup.parent_surname')}
                            required
                            errorMsg={errors?.parentLastName?.message}
                            {...register('parentLastName')}
                          />
                        </div>

                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            type='email'
                            className='w-full'
                            label={t('signup.parent_email')}
                            required
                            errorMsg={errors?.email?.message}
                            {...register('email')}
                          />
                        </div>
                        <div className='col-span-6'>
                          <div className='flex flex-col gap-2 sm:w-1/2'>
                            <div className='w-full'>
                              <label
                                htmlFor='whatsapp'
                                className='block text-sm font-medium text-gray-700'
                              >
                                {t('signup.parent_phone')}
                                <span className='text-error'>*</span>
                              </label>
                              <div className='mt-1 flex rounded-md shadow-sm'>
                                <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                                  +7
                                </span>
                                <input
                                  type='text'
                                  name='whatsapp'
                                  id='whatsapp'
                                  className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                                  // placeholder='www.example.com'
                                  // errorMsg={
                                  //   errors?.whatsapp?.type ? t('signup.parent_phone_error') : ''
                                  // }
                                  {...register('whatsapp')}
                                />
                              </div>
                              {errors?.whatsapp?.message && (
                                <span className='mt-1 text-xs font-semibold text-error'>
                                  {errors?.whatsapp?.message}
                                </span>
                              )}
                            </div>

                            <div className='w-full'>
                              <label
                                htmlFor='whatsappCopy'
                                className='block text-sm font-medium text-gray-700'
                              >
                                {t('signup.confirm_phone')}
                                <span className='text-error'>*</span>
                              </label>
                              <div className='mt-1 flex rounded-md shadow-sm'>
                                <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                                  +7
                                </span>
                                <input
                                  type='text'
                                  name='whatsappCopy'
                                  id='whatsappCopy'
                                  className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                                  // placeholder='www.example.com'
                                  {...register('whatsappCopy', {
                                    required: true,
                                    minLength: 10,
                                    maxLength: 10,
                                    // validate: (val) => {
                                    //   if (watch('whatsapp') !== val) {
                                    //     return t('signup.confirm_error');
                                    //   }
                                    // },
                                  })}
                                  // errorMsg={
                                  //   errors?.whatsappCopy?.type === 'required'
                                  //     ? t('signup.parent_phone_error')
                                  //     : errors?.whatsappCopy?.message
                                  // }
                                />
                              </div>
                              {errors?.whatsappCopy?.message && (
                                <span className='mt-1 text-xs font-semibold text-error'>
                                  {errors?.whatsappCopy?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-span-6 sm:col-span-3'>
                          <Controller
                            control={control}
                            name='language'
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                label={t('signup.study_lang')}
                                required
                                isSearchable={false}
                                errorMsg={errors?.language?.message}
                                className='w-full'
                                value={field.value}
                                options={[
                                  {
                                    label: 'Қазақша',
                                    value: 'K',
                                  },
                                  {
                                    label: 'Русский',
                                    value: 'R',
                                  },
                                ]}
                                // placeholder={t('announcement.select_positions')}
                                onChange={(e) => field.onChange(e.value)}
                              />
                            )}
                          />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                          <Input
                            type='text'
                            className='w-full'
                            label={t('signup.enter_school_name')}
                            required
                            errorMsg={errors?.school?.message}
                            {...register('school')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='hidden sm:block' aria-hidden='true'>
              <div className='py-5'>
                <div className='border-t border-gray-200' />
              </div>
            </div>
            {/* docs */}
            <div className='mt-10 sm:mt-0'>
              <div className='md:grid md:grid-cols-4 md:gap-6'>
                <div className='md:col-span-1'>
                  <div className='px-4 sm:px-0'>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Документы
                    </h3>
                  </div>
                </div>
                <div className='mt-5 md:col-span-3 md:mt-0'>
                  <div className='shadow sm:rounded-md'>
                    <div className='space-y-6 bg-white px-4 py-5 sm:p-6'>
                      <div className='grid grid-cols-6 gap-6'>
                        <div className='col-span-6 sm:col-span-2'>
                          <span className='block text-sm font-medium text-gray-700'>
                            Фото ребенка
                            <span className={`text-error`}>*</span>
                          </span>
                          <div>
                            <div className='flex w-full flex-col justify-center'>
                              <div className='mb-2 box-border flex h-72 w-full flex-col items-center justify-center rounded-md border bg-white align-middle'>
                                {avatar ? (
                                  <img
                                    src={URL.createObjectURL(avatar)}
                                    alt='userPhoto'
                                    className='h-64'
                                  />
                                ) : (
                                  <UserIcon className='h-24 w-24' />
                                )}
                              </div>
                              {!avatar && (
                                <span className='mb-2 text-xs font-semibold text-error'>
                                  {t('signup.please_upload_photo')}
                                </span>
                              )}
                              <Button
                                type='button'
                                text={t('signup.upload_photo')}
                                onClick={() => setOpen(true)}
                              />
                            </div>
                            <UpdateAvatar
                              open={open}
                              setOpen={setOpen}
                              avatar={avatar}
                              setAvatar={setAvatar}
                            />
                          </div>
                        </div>

                        <div className='col-span-6 sm:col-span-1'></div>

                        <div className='col-span-6 sm:col-span-2'>
                          <span className='block text-sm font-medium text-gray-700'>
                            Свидетельство о рождении
                            <span className={`text-error`}>*</span>
                          </span>
                          <div>
                            <div className='flex w-full flex-col justify-center'>
                              <div className='mb-2 box-border flex h-72 w-full flex-col items-center justify-center rounded-md border bg-white align-middle'>
                                {doc ? (
                                  <img
                                    src={URL.createObjectURL(doc)}
                                    alt='userPhoto'
                                    className='h-64'
                                  />
                                ) : (
                                  <UserIcon className='h-24 w-24' />
                                )}
                              </div>
                              {!doc && (
                                <span className='mb-2 text-xs font-semibold text-error'>
                                  {t('signup.please_upload_photo')}
                                </span>
                              )}
                              <Button
                                type='button'
                                text={t('signup.upload_docs')}
                                onClick={() => fileRef.current.click()}
                              />
                              <input
                                hidden
                                type='file'
                                accept='image/*'
                                ref={fileRef}
                                onChange={(e, d) => {
                                  setDoc(e.target.files[0]);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-span-6'>
                          <div className='hidden sm:block' aria-hidden='true'>
                            <div className='py-5'>
                              <div className='border-t border-gray-200' />
                            </div>
                          </div>
                        </div>

                        <div className='col-span-6'>
                          <Controller
                            control={control}
                            name='socialDocType'
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                label={t('signup.question_1')}
                                required
                                errorMsg={errors?.socialDocType?.message}
                                value={field?.value}
                                isSearchable={false}
                                className='w-full'
                                options={[
                                  {
                                    label: t('signup.not_in_category'),
                                    value: 'not_in',
                                  },
                                  ...vulnerableGroups.map((group, i) => ({
                                    label:
                                      `${i + 1}. ` +
                                      (i18n.language === 'kk'
                                        ? group?.name
                                        : group?.name_ru),
                                    value: group?.uuid,
                                    docs_list: {
                                      kz: group?.files,
                                      ru: group?.files_ru,
                                    },
                                    data: group,
                                  })),
                                ]}
                                // placeholder={t('announcement.select_positions')}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                              />
                            )}
                          />
                          <div className='col-span-6 sm:col-span-6'>
                            <div className='md:grid md:grid-cols-3 md:gap-6'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-10 flex flex-row justify-end'>
              <Button type='submit' text='Добавить заявку' loading={loading} />
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-row justify-center'>регистрация закрыта</div>
  );
}

function UpdateAvatar({ open, setOpen, setAvatar }) {
  const [t] = useTranslation();

  const [userPhoto, setUserPhoto] = useState(null);
  const [editor, setEditor] = useState(null);
  const fileRef = useRef(null);
  const [scale, setScale] = useState(1);
  // const [showMsg, setShowMsg] = useState(false);

  const setEditorRef = (editor) => setEditor(editor);

  const saveAvatar = async () => {
    const img = editor.getImageScaledToCanvas();

    img.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileRef?.current?.files[0].name, {
          type: 'image',
        });
        setAvatar(file);
      }
    });

    setOpen(false);
  };

  const handleScale = (e) => {
    const scaleEvent = parseFloat(e.target.value);
    setScale(scaleEvent);
  };

  return (
    <CustomDialog
      isOpen={open}
      setIsOpen={setOpen}
      closeModal={() => {
        setOpen(false);
      }}
      headerText={t('signup.upload_photo')}
    >
      <div className='flex flex-col gap-2 sm:p-4'>
        <AvatarEditor
          ref={setEditorRef}
          image={userPhoto && userPhoto}
          // width={250}
          // height={250}
          className='w-full'
          border={50}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={scale}
          rotate={0}
        />

        <div className='flex items-center justify-center gap-2'>
          <label>Масштаб</label>
          <input
            name='scale'
            type='range'
            onChange={(e) => handleScale(e)}
            min={'0.5'}
            max='2'
            step='0.02'
            defaultValue='1'
          />
        </div>

        <div className='mt-4 flex flex-row justify-end gap-4'>
          <Button
            type='button'
            text={t('buttons.select')}
            onClick={() => fileRef.current.click()}
          />
          <input
            hidden
            type='file'
            accept='image/*'
            ref={fileRef}
            onChange={(e, d) => {
              setUserPhoto(e.target.files[0]);
            }}
          />

          <Button
            // disabled={!fileRef?.current?.files[0]}
            type='button'
            text={t('buttons.save')}
            onClick={saveAvatar}
            color='success'
          />
        </div>
      </div>
    </CustomDialog>
  );
}
