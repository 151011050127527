import examAPI from '../examAPI';

export const clearCacheBackend = () => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .get(`/core/clear_cache/`)
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getSettings = ({ allowed_schools, code }) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .get(`/core/settings/`, {
          params: {
            allowed_schools,
            code,
          },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchSetting = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .patch(`/core/settings/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
