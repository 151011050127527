import bilAPI from '../api';

export const getSchoolsAcceptanceStats = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/results/schools_acceptance_stats_api/`, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExtraAllowedStudents = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/results/extra_allowed_students/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postExtraAllowedStudents = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/results/extra_allowed_students/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const patchExtraAllowedStudents = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/results/extra_allowed_students/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
