import React from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import good_example from '../../../assets/images/uploadExamples/good_example.jpg';
import bad_example_1 from '../../../assets/images/uploadExamples/bad_example_1.jpg';
import bad_example_2 from '../../../assets/images/uploadExamples/bad_example_2.jpg';
import bad_example_3 from '../../../assets/images/uploadExamples/bad_example_3.jpg';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Attention = ({ setIndex }) => {
  const [t] = useTranslation();

  return (
    <>
      <div className='flex flex-col items-center justify-center sm:flex-row'>
        <div className='flex flex-row'>
          <div className='relative w-1/2'>
            <div className='absolute h-full w-full bg-error opacity-20' />
            <XMarkIcon className='absolute w-full text-error' />

            <img src={bad_example_1} alt='Bad exapmle 1' />
          </div>
          <div className='relative w-1/2'>
            <div className='absolute h-full w-full bg-error opacity-20' />
            <XMarkIcon className='absolute w-full text-error' />

            <img src={bad_example_2} alt='Bad exapmle 2' />
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='relative w-1/2'>
            <div className='absolute h-full w-full bg-error opacity-20' />
            <XMarkIcon className='absolute w-full text-error' />
            <img src={bad_example_3} alt='Bad exapmle 3' />
          </div>
          <div className='relative w-1/2'>
            <img src={good_example} alt='Good exapmle' />
          </div>
        </div>
      </div>
      <div className='pl-2 text-sm sm:text-base'>
        <span>{t('signup.upload_image.requirements_to_image')}</span>
        <ol className='list-disc'>
          {[...Array(6).keys()].map((i) => (
            <li key={i}>{t(`signup.upload_image.requirement_${i + 1}`)}</li>
          ))}
        </ol>
      </div>
      <Button
        className='text-sm sm:text-base'
        text={t('buttons.next')}
        onClick={() => setIndex(1)}
      />
    </>
  );
};

export default Attention;
