import React, { useEffect, useState } from 'react';
import { getAnswers } from '../../../../../services/results/answerService';
import SecretCompVersion from './SecretCompVersion';
import SecretMobVersion from './SecretMobVersion';

export default function SecretNewResultForm({ result }) {
  const [language, setLanguage] = useState([]);
  const [math, setMath] = useState([]);

  useEffect(() => {
    if (result?.uuid) {
      getAnswers({
        page_size: 60,
        application: result?.application?.uuid,
      }).then((res) => {
        let arr = [];
        let mathAns = res.results.filter((q) => q.question.type === 0),
          langAns = res.results.filter((q) => q.question.type === 1);
        while (mathAns?.length > 0) {
          arr.push(mathAns.splice(0, 30));
        }
        setMath(arr);
        arr = [];
        while (langAns?.length > 0) {
          arr.push(langAns.splice(0, 30));
        }
        setLanguage(arr);
      });
    }

    return () => {};
  }, [result?.application?.uuid, result?.uuid]);

  return (
    <div className='overflow-hidden text-xs sm:text-sm md:text-base'>
      <div className='hidden gap-8 xl:flex xl:flex-col '>
        <SecretCompVersion
          CompVersion
          math={math}
          language={language}
          result={result}
        />
      </div>
      <div className='flex flex-col items-center gap-8 px-4 xl:hidden'>
        <SecretMobVersion
          math={math.reduce((p, c) => [...p, ...c], [])}
          language={language.reduce((p, c) => [...p, ...c], [])}
          result={result}
        />
      </div>
    </div>
  );
}
