import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../utils/contexts/UserContext';
import { PageButton } from '../../../../components/forms/Buttons/PageButton';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import {
  getMyPlaceSecret,
  getResultsSecret,
} from '../../../../services/results/secretResultService';

const PAGE_SIZE = 200;

export default function SecretSchoolResults() {
  const [t] = useTranslation();
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [myPlace, setMyPlace] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const { user } = useContext(UserContext);
  const scrollRef = useRef();
  const myPlaceRef = useRef();
  const [scrolled, setScrolled] = useState(false);

  const getResultsData = useCallback(() => {
    getResultsSecret({ page_size: PAGE_SIZE, page }).then((res) => {
      setResults(res?.results);
      setCount(res.count);
      setPageCount(Math.ceil(res.count / PAGE_SIZE));
    });
  }, [page]);

  useEffect(() => {
    if (results.length > 0 && myPlaceRef.current && !scrolled) {
      myPlaceRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setScrolled(true);
    }
  }, [results.length, scrolled]);

  useEffect(() => {
    if (myPlace) getResultsData();
    else {
      getMyPlaceSecret().then((res) => {
        setPage(Math.ceil(res.place / PAGE_SIZE));
        setMyPlace(res.place);
      });
    }
    return () => {};
  }, [getResultsData, myPlace]);

  return (
    <div className='flex w-full flex-col gap-2 '>
      <div className='flex flex-col items-center sm:flex sm:flex-1 sm:items-center sm:justify-between'>
        <div className='flex gap-x-2'>
          {count && (
            <div>
              Всего: <span className='font-bold'>{count}</span>
            </div>
          )}
          {page && (
            <span className=' text-gray-700'>
              | Страница <span className='font-medium'>{page}</span> из
              <span className='font-medium'>{' ' + pageCount}</span>
            </span>
          )}
        </div>
        {pageCount > 1 && (
          <div>
            <nav
              className='relative z-0 inline-flex -space-x-px rounded-md shadow-sm'
              aria-label='Pagination'
            >
              <PageButton
                className='rounded-l-md'
                onClick={() => {
                  setPage(1);
                  scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                disabled={page === 1}
              >
                <span className='sr-only'>First</span>
                <ChevronDoubleLeftIcon className='h-5 w-5' aria-hidden='true' />
              </PageButton>
              <PageButton
                onClick={() => {
                  setPage((p) => p - 1);
                  scrollRef.current.scrollTo(0, 0);
                }}
                disabled={page === 1}
              >
                <span className='sr-only'>Previous</span>
                <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
              </PageButton>
              <PageButton
                onClick={() => {
                  setPage((p) => p + 1);
                  scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                disabled={page === pageCount}
              >
                <span className='sr-only'>Next</span>
                <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
              </PageButton>
              <PageButton
                className='rounded-r-md'
                onClick={() => {
                  setPage(pageCount);
                  scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                disabled={page === pageCount}
              >
                <span className='sr-only'>Last</span>
                <ChevronDoubleRightIcon
                  className='h-5 w-5'
                  aria-hidden='true'
                />
              </PageButton>
            </nav>
          </div>
        )}
      </div>
      <div
        className='flex h-[36rem] w-full flex-row overflow-auto text-xs sm:text-sm md:text-base'
        ref={scrollRef}
      >
        <table className='w-full border-separate border-spacing-0 bg-white'>
          <thead>
            <tr className='sticky top-0 z-20 bg-white'>
              <th className='sticky left-0 z-10 w-12 min-w-[3rem] border bg-white p-1'>
                #
              </th>
              <th className='sticky left-12 z-10 border bg-white p-2'>
                Username
              </th>
              <th className='border p-2'>{t('signup.iin')}</th>
              <th className='break-normal border p-2 font-normal'>
                {t('results.math_correct')}
              </th>
              <th className='break-normal border p-2 font-normal'>
                {t('results.math_wrong')}
              </th>
              <th className='break-normal border p-2'>
                {t('results.math_points')}
              </th>
              <th className='break-normal border p-2 font-normal'>
                {t('results.language_correct')}
              </th>
              <th className='border p-2 font-normal'>
                {t('results.language_wrong')}
              </th>
              <th className='border p-2'> {t('results.language_points')}</th>
              <th className='border p-2 font-normal'>{t('results.correct')}</th>
              <th className='border p-2 font-normal'>{t('results.wrong')}</th>
              <th className='border p-2'> {t('results.points')}</th>
              <th className='border p-2'>{t('results.result')}</th>
              <th className='border p-2'>{t('signup.asp')}</th>
            </tr>
          </thead>
          <tbody>
            {results?.map((result, i) => {
              let is_owner = user.username === result.username;
              return (
                <tr
                  key={result.uuid}
                  className={is_owner ? 'bg-blue-200' : ''}
                  ref={is_owner ? myPlaceRef : null}
                >
                  <td
                    className={`sticky left-0 z-10 w-12 border p-1 text-center ${
                      is_owner ? 'bg-blue-200' : 'bg-white'
                    }`}
                  >
                    {i + 1 + PAGE_SIZE * (page - 1)}
                  </td>
                  <td
                    className={`sticky left-12 z-10 w-32 border p-2 ${
                      is_owner ? 'bg-blue-200' : 'bg-white'
                    }`}
                  >
                    {result.username}
                  </td>
                  <td className='border p-2'>
                    {is_owner ? user.application.iin : result.iin}
                  </td>
                  <td className='border p-2'>{result.math_correct}</td>
                  <td className='border p-2'>{result.math_wrong}</td>
                  <td className='border p-2'>{result.math_points}</td>
                  <td className='border p-2'>{result.language_correct}</td>
                  <td className='border p-2'>{result.language_wrong}</td>
                  <td className='border p-2'>{result.language_points}</td>
                  <td className='border p-2'>{result.total_correct}</td>
                  <td className='border p-2'>{result.total_wrong}</td>
                  <td className='border p-2'>{result.result}</td>
                  <td className='border p-2'>
                    {result?.status === 0 && t('results.not_passed')}
                    {result?.status === 1 && t('results.reserve')}
                    {result?.status === 2 && (
                      <span className='flex- font-semibold text-green-600'>
                        {t('results.passed')}
                      </span>
                    )}
                  </td>
                  <td className='border p-2'>{result.is_asp ? 'Да' : 'Нет'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
