import React, { useContext, useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import { Button } from '../../components/forms/Buttons/Button';
import {
  getResults,
  getResultsStats,
} from '../../services/results/resultsService';
import { useNavigate } from 'react-router-dom';
import { getSchools } from '../../services/catalog/catalog';
import Input from '../../components/forms/Input/Input';
import {
  EyeIcon,
  IdentificationIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import TableWithServerOrdering from '../../components/Table/TableWithServerOrdering';
import { UserContext } from '../../utils/contexts/UserContext';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';

export default function ResultsAdmin() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { examYear } = useContext(ExamYearContext);

  const [resultsData, setResultsData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [round, setRound] = useState(null);
  const [isAsp, setIsAsp] = useState(null);
  const [isMynbala, setIsMynbala] = useState(null);
  const [status, setStatus] = useState(null);
  const [school, setSchool] = useState(null);
  const [schools, setSchools] = useState([]);
  const [iinToSearch, setIinToSearch] = useState('');
  const [ordering, setOrdering] = useState(null);
  const [resultsStats, setResultsStats] = useState({});

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'index',
        Cell: (props) => {
          return (
            <span className='text-center'>
              {props.row.index + 1 + (page - 1) * pageSize}
            </span>
          );
        },
      },
      {
        Header: 'Мектеп',
        accessor: 'application.school.name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'ЖСН',
        accessor: 'application.iin',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Аты',
        accessor: 'application.first_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: 'Жөні',
        accessor: 'application.last_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      {
        Header: 'Вариант',
        accessor: 'exam_variant',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='text-center'>
            <span className=''>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Дұрыс',
        accessor: 'total_correct',
        accessor_for_ordering: 'total_correct',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='bg-green-100 text-center'>
            <span className='font-semibold text-green-800'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Қате',
        accessor: 'total_wrong',
        accessor_for_ordering: 'total_wrong',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='bg-red-100 text-center'>
            <span className='font-semibold text-red-700'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Ұпай',
        accessor: 'result',
        accessor_for_ordering: 'result',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='bg-blue-100 text-center'>
            <span className='font-semibold'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: (props) => (
          <div className='text-center'>
            {props.value === 0 && (
              <span className='font-semibold text-red-600'>Өтпеді</span>
            )}
            {props.value === 1 && <span className=''>Резерв</span>}
            {props.value === 2 && (
              <span className='font-semibold text-green-600'>Өтті</span>
            )}
          </div>
        ),
      },

      {
        Header: 'ӘОТ',
        accessor: 'is_asp',
        Cell: (props) => (
          <>
            {props.value ? (
              <span className=''>Да</span>
            ) : (
              <span className=''>Нет</span>
            )}
          </>
        ),
      },
      {
        Header: '1000 бала',
        accessor: 'is_myn_bala',
        Cell: (props) => (
          <>
            {props.value ? (
              <span className=''>1000 бала</span>
            ) : (
              <span className=''></span>
            )}
          </>
        ),
      },
      {
        Header: 'Тел номер',
        accessor: 'application.parent_phone_number',
      },
      {
        Header: 'Пропуск',
        accessor: '',
        Cell: (props) => (
          // props.row.original.status === 2 ? (
          <div className='text-center'>
            <a
              href={`/results/${props.row.original.application.uuid}/pass`}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                Icon={EyeIcon}
                className='max-h-6'
                // onClick={() => {
                //   navigate(
                //     `/results/${props.row.original.application.uuid}/pass`,
                //     {
                //       state: {
                //         socialDocType: props.row.original.social_doc_type,
                //       },
                //     },
                //   );
                // }}
              />
            </a>
          </div>
        ),
        // ) : (
        //   <div></div>
        // ),
      },
      {
        Header: 'Заявление',
        accessor: '',
        Cell: (props) => (
          <div className='text-center'>
            <a
              href={`/results/${props.row.original.application.uuid}/application`}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                Icon={IdentificationIcon}
                className='max-h-6'
                // onClick={() => {
                //   navigate(
                //     `/results/${props.row.original.application.uuid}/application`,
                //   );
                // }}
              />
            </a>
          </div>
        ),
      },
      {
        Header: 'Ответы',
        accessor: '',
        Cell: (props) => (
          <div className='text-center'>
            {props.row.original.exam_year >= 2024 && (
              <a
                href={`/results/${props.row.original.application.uuid}/answers`}
                target='_blank'
                rel='noreferrer'
              >
                <Button
                  Icon={IdentificationIcon}
                  className='max-h-6'
                  // onClick={() => {
                  //   navigate(
                  //     `/results/${props.row.original.application.uuid}/answers`,
                  //   );
                  // }}
                />
              </a>
            )}
          </div>
        ),
      },
    ],
    [page, pageSize],
  );

  useEffect(() => {
    setLoading(true);
    getSchools({ page_size: 50 }).then((res) => {
      const options = res.results.map((school) => ({
        label: school.name,
        value: school.uuid,
      }));

      setSchools(options);
    });
    getResults({ page: 1, page_size: 50, exam_year: examYear?.uuid })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / 50));
      })
      .finally(() => {
        setLoading(false);
      });
    getResultsStats({ exam_year: examYear?.uuid }).then((res) => {
      setResultsStats(res);
    });

    return () => {};
  }, [examYear?.uuid]);

  const nextPage = () => {
    setLoading(true);

    getResults({
      round: round,
      is_asp: isAsp,
      status: status,
      school: school,
      name: iinToSearch,
      ordering: ordering,
      page: page + 1,
      page_size: pageSize,
      exam_year: examYear?.uuid,
      is_myn_bala: isMynbala,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getResults({
      round: round,
      is_asp: isAsp,
      status: status,
      school: school,
      name: iinToSearch,
      ordering: ordering,
      page: page - 1,
      page_size: pageSize,
      exam_year: examYear?.uuid,
      is_myn_bala: isMynbala,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getResults({
      round: round,
      is_asp: isAsp,
      status: status,
      school: school,
      name: iinToSearch,
      ordering: ordering,
      page: pageNum,
      page_size: pageSize,
      exam_year: examYear?.uuid,
      is_myn_bala: isMynbala,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setPage(pageNum);
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const handleOrdering = (newOrdering) => {
    newOrdering = `-${newOrdering}`;
    if (ordering === newOrdering) {
      newOrdering = ordering.substring(1);
    }

    setOrdering(newOrdering);

    setLoading(true);
    getResults({
      round: round,
      name: iinToSearch,
      ordering: newOrdering,
      is_asp: isAsp,
      status: status,
      school: school,
      page: 1,
      page_size: pageSize,
      exam_year: examYear?.uuid,
      is_myn_bala: isMynbala,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setPage(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row justify-end'>
        <div className='flex flex-col'>
          <p>
            Participated:{' '}
            <span className='font-bold'>{resultsStats?.participated}</span>
          </p>
          <p>
            Participated (ASP):{' '}
            <span className='font-bold'>{resultsStats?.participated_asp}</span>
          </p>
          <p>
            Accepted:{' '}
            <span className='font-bold'>{resultsStats?.accepted}</span>
          </p>
          <p>
            Accepted (ASP):{' '}
            <span className='font-bold'>{resultsStats?.accepted_asp}</span>
          </p>
        </div>
      </div>
      <div className='flex flex-row justify-between'>
        {user?.roles?.some((role) => role?.code === 'SSA') ? (
          <Button
            text='Отправить файл'
            onClick={() => {
              navigate('upload');
            }}
          />
        ) : (
          <></>
        )}
        <div className='flex flex-row gap-2'>
          <Input
            type='text'
            className='w-72'
            placeholder='Поиск по ИИН, имени и фамилии'
            value={iinToSearch}
            onChange={(e, d) => {
              setIinToSearch(e.target.value);
            }}
          />
          <Button
            type='submit'
            text='Поиск'
            color='primary'
            className='h-9'
            onClick={() => {
              setLoading(true);
              getResults({
                round: round,
                is_asp: isAsp,
                status: status,
                name: iinToSearch,
                ordering: ordering,
                school: school,
                page: 1,
                page_size: pageSize,
                exam_year: examYear?.uuid,
                is_myn_bala: isMynbala,
              })
                .then((res) => {
                  setResultsData(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          />
          <Button
            type='button'
            Icon={XMarkIcon}
            className='h-9'
            onClick={() => {
              setLoading(true);

              getResults({
                round: round,
                is_asp: isAsp,
                status: status,
                ordering: ordering,
                school: school,
                page: 1,
                page_size: pageSize,
                exam_year: examYear?.uuid,
                is_myn_bala: isMynbala,
              })
                .then((res) => {
                  setResultsData(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                  setIinToSearch('');
                });
            }}
          />
        </div>
      </div>
      <div className='flex flex-row justify-end gap-2'>
        <Select
          isDisabled={loading}
          className={`w-48 ${examYear?.year >= 2024 && 'hidden'}`}
          value={round}
          isSearchable={false}
          options={[
            {
              label: 'Все туры',
              value: null,
            },
            ...[1, 2, 3, 4].map((v, i) => ({
              label: `${v} тур`,
              value: `${i}`,
            })),
          ]}
          // placeholder={t('announcement.select_positions')}
          onChange={(e) => {
            setLoading(true);

            getResults({
              round: e.value,
              is_asp: isAsp,
              status: status,
              name: iinToSearch,
              ordering: ordering,
              school: school,
              page: 1,
              page_size: pageSize,
              exam_year: examYear?.uuid,
              is_myn_bala: isMynbala,
            })
              .then((res) => {
                setResultsData(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setLoading(false);
                setRound(e.value);
              });
          }}
        />
        <Select
          isDisabled={loading}
          className='w-48'
          value={school}
          options={[
            {
              label: 'Все школы',
              value: null,
            },
            ...schools,
          ]}
          placeholder='Мектеп'
          onChange={(e) => {
            setLoading(true);

            getResults({
              round: round,
              is_asp: isAsp,
              name: iinToSearch,
              ordering: ordering,
              status: status,
              school: e.value,
              page: 1,
              page_size: pageSize,
              exam_year: examYear?.uuid,
              is_myn_bala: isMynbala,
            })
              .then((res) => {
                setResultsData(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setLoading(false);
                setSchool(e.value);
              });
          }}
        />
        <Select
          isDisabled={loading}
          className='w-48'
          value={isAsp}
          isSearchable={false}
          options={[
            {
              label: 'Все категории',
              value: null,
            },
            {
              label: 'ӘОТ',
              value: true,
            },
            {
              label: 'ӘОТ емес',
              value: false,
            },
          ]}
          placeholder='ӘОТ'
          onChange={(e) => {
            setLoading(true);

            getResults({
              round: round,
              is_asp: e.value,
              name: iinToSearch,
              ordering: ordering,
              status: status,
              school: school,
              page: 1,
              page_size: pageSize,
              exam_year: examYear?.uuid,
              is_myn_bala: isMynbala,
            })
              .then((res) => {
                setResultsData(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setLoading(false);
                setIsAsp(e.value);
              });
          }}
        />
        <Select
          isDisabled={loading}
          className='w-48'
          value={status}
          isSearchable={false}
          options={[
            {
              label: 'Все cтатусы',
              value: null,
            },
            {
              label: 'Өтті',
              value: 2,
            },
            {
              label: 'Өтпеді',
              value: 0,
            },
            // {
            //   label: 'Резерв',
            //   value: 1,
            // },
          ]}
          placeholder='Статус'
          onChange={(e) => {
            setLoading(true);

            getResults({
              round: round,
              is_asp: isAsp,
              name: iinToSearch,
              ordering: ordering,
              status: e.value,
              school: school,
              page: 1,
              page_size: pageSize,
              exam_year: examYear?.uuid,
              is_myn_bala: isMynbala,
            })
              .then((res) => {
                setResultsData(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setLoading(false);
                setStatus(e.value);
              });
          }}
        />
        <Select
          isDisabled={loading}
          className='w-48'
          value={isMynbala}
          isSearchable={false}
          options={[
            {
              label: 'Все категории',
              value: null,
            },
            {
              label: '1000 бала',
              value: true,
            },
            {
              label: '1000 бала емес',
              value: false,
            },
          ]}
          placeholder='1000 бала'
          onChange={(e) => {
            setLoading(true);

            getResults({
              round: round,
              is_asp: isAsp,
              name: iinToSearch,
              ordering: ordering,
              status: status,
              school: school,
              page: 1,
              page_size: pageSize,
              exam_year: examYear?.uuid,
              is_myn_bala: e.value,
            })
              .then((res) => {
                setResultsData(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setLoading(false);
                setIsMynbala(e.value);
              });
          }}
        />
        {/* <Button className='h-9' text='Экспорт' color='success' /> */}
      </div>
      <TableWithServerOrdering
        columns={columns}
        data={resultsData}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
        ordering={ordering}
        handleOrdering={handleOrdering}
      />
    </div>
  );
}
